import { CheckCircleIcon, CheckIcon, DownloadIcon, XCircleIcon, XIcon, BanIcon, TrashIcon } from "@heroicons/react/outline"
import { useState } from 'react'
import queryKeys from "config/queries"
import axios from "helpers/axios"
import { useMutation, useQueryClient } from "react-query"
import Shareholder from "types/Shareholder"
import ModalAction from 'components/Modal/ModalAction'
import { Toaster } from 'react-hot-toast';
import { notifySuccess, notifyError } from 'components/Toast/ToastNotify'
import { base64ToDocument } from "utils/functions"

interface ShareholderListItemProps {
    shareholder: Shareholder
    onSelect: (shareholderId: string) => void
    setDeleteShareholding: (show: boolean) => void
    deleteShareholding: boolean
}

const ShareholderListItem = ({ shareholder, onSelect, setDeleteShareholding, deleteShareholding }: ShareholderListItemProps) => {

    const [showModalAction, setShowModalAction] = useState(false)
    const [titleModal, setTitleModal] = useState("")
    const [submitButtonLabel, setSubmitButtonLabel] = useState("")

    // const handleDownloadPower = () => window.open(shareholder.powerUrl, "_blank")

    const queryClient = useQueryClient()
    const { mutate: handleVerifyPower } = useMutation((accepted: boolean) => axios.patch(`/shareholders/${shareholder._id}/validate-power`, { accepted }), {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.SHAREHOLDERS)
        }
    })

    const { mutate: downloadDocument, isLoading } = useMutation((documentId: string) => axios.get(`/transactions/files/${documentId}`).then(res => res.data), {
        onSuccess: (reportURL) => {
            base64ToDocument(reportURL, shareholder.powerUrl);
            notifySuccess("Documento descargado")
        },
        onError: () => {
            notifyError("No se a podido descargar")
        }
    })

    const onSubmit = async () => {
        try {
            if (submitButtonLabel === "Eliminar") {
                const { data, status } = await axios.delete<any>(`/shareholders/${shareholder._id}`)
                if (data === 403 && status === 200) {
                    notifyError("No se puede borrar el accionista ya que tiene transacciones activas.");
                } else {
                    notifySuccess('Accionista eliminado');
                }
            } else {
                if (submitButtonLabel === "Deshabilitar") {
                    await axios.put<any>(`/shareholders/${shareholder._id}`)
                    notifySuccess('Accionista deshabilitado');
                }
            }
            setSubmitButtonLabel("")
            setShowModalAction(false)
            setDeleteShareholding(!deleteShareholding)
        } catch (error) {
            console.log(error)
            setShowModalAction(false)
            return error
        }
    }

    const deleteshareholders = async () => {
        try {
            setTitleModal("Eliminar accionista")
            setSubmitButtonLabel("Eliminar")
            setShowModalAction(true)
        } catch (error) {
            alert("Intenta de nuevo")
            return error
        }
    }

    const disableshareholders = async () => {
        try {
            setTitleModal("Deshabilitar accionista")
            setSubmitButtonLabel("Deshabilitar")
            setShowModalAction(true)
        } catch (error) {
            alert("Intenta de nuevo")
            return error
        }
    }

    return (
        <tr>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                <button onClick={() => onSelect(shareholder._id)} className="font-medium text-gray-900 hover:underline focus:outline-none focus:ring-2 focus:ring-blue-500 rounded">{shareholder.fullName}</button>
                <span className="text-gray-500 font-normal">
                    <br /><span>{shareholder.professionalEmail}</span>
                    <br /><span>{shareholder.personalEmail}</span>
                </span>
                <div className="py-1 sm:flex">
                    <button type="button" onClick={deleteshareholders} className="inline-flex items-center px-2 py-0.5 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                        <TrashIcon className="h-5 mr-2" aria-hidden="true" />
                        Eliminar
                    </button>
                    <button type="button" onClick={disableshareholders} className="sm:ml-2 inline-flex items-center px-2 py-0.5 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
                        <BanIcon className="h-5 mr-2" aria-hidden="true" />
                        Deshabilitar
                    </button>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {shareholder.company ? shareholder.company : "-"}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 ">
                <div className="flex justify-center">
                    {
                        shareholder.hasSalary ? <CheckCircleIcon className="h-6 w-6 text-green-500" /> : <XCircleIcon className="h-6 w-6 text-yellow-500" />
                    }
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <div className="flex justify-center">
                    {
                        shareholder.hasPower ? <CheckCircleIcon className="h-6 w-6 text-green-500" /> : <XCircleIcon className="h-6 w-6 text-yellow-500" />
                    }
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 ">
                <div className="flex justify-center">
                    {
                        shareholder.powerVerified ? <CheckCircleIcon className="h-6 w-6 text-green-500" /> : <XCircleIcon className="h-6 w-6 text-yellow-500" />
                    }
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 w-32 break-words">
                {
                    shareholder.powerUrl
                        ? <button
                            onClick={() => downloadDocument(shareholder.powerUrl)}
                            className="flex items-center bg-gray-50 hover:bg-gray-100 p-2 rounded hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
                            <DownloadIcon className="h-6 w-6 mr-2" />
                            <span>Descargar</span>
                        </button>
                        : <div className=" whitespace-normal w-full">
                            Documento no subido a la plataforma
                        </div>
                }
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {
                    (shareholder.powerUrl && !shareholder.powerVerified) && <div className="flex justify-center">
                        <button onClick={() => handleVerifyPower(true)} className="rounded bg-green-500 text-white mr-2"><CheckIcon className="h-5" /></button>
                        <button onClick={() => handleVerifyPower(false)} className="rounded bg-red-500 text-white"><XIcon className="h-5" /></button>
                    </div>
                }

            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center items-center">
                {shareholder.countdown.days > 0 &&
                    <p className={`h-10 w-12 text-lg m-auto flex items-center justify-center ${shareholder.countdown.days > 60 ? "text-white bg-red-500 rounded-md" : ""}`}>
                        {shareholder.countdown.days}
                    </p>
                }
            </td>
            <ModalAction title={titleModal} subTitle={shareholder.fullName} isVisible={showModalAction} submitButtonLabel={submitButtonLabel} onClose={setShowModalAction} onSubmit={onSubmit} />
            <Toaster />
        </tr>
    )

}

export default ShareholderListItem