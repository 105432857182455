import queryKeys from "config/queries"
import axios from "helpers/axios"
import { useQueryClient } from "react-query"

const useEditAnnouncement = (id: string) => {
    const queryClient = useQueryClient()

    const deleteAnnouncement = async () => {
        try {
            await axios.delete(`/announcements/${id}`)
            queryClient.invalidateQueries(queryKeys.ANNOUNCEMENTS)
        } catch (err) {
            throw err
        }
    }

    return { deleteAnnouncement }
}

export default useEditAnnouncement