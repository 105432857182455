import Button from "components/Button/Button"
import Input from "components/Input/Input"
import SettingsSectionLayout from "../SettingsSectionLayout"
import { useForm } from "react-hook-form"
import axios from "helpers/axios"
import queryKeys from "config/queries"
import { useQuery, useQueryClient } from "react-query"
import ShareValue from "types/ShareValue"
import ShareValuesList from "./ShareValuesList"
import { HTMLInputDateToDate } from "helpers"

const ShareValueConfig = () => {

    const { register, handleSubmit, errors, reset } = useForm()

    const queryClient = useQueryClient()

    const { data: shareValues } = useQuery([queryKeys.SHARE_VALUES], () => axios.get<Array<ShareValue>>("/share-values").then(res => res.data))

    console.log(shareValues)

    const onSubmit = async (data: any) => {
        try {
            await axios.post("/share-values", { ...data, from: HTMLInputDateToDate(data.from) })
            queryClient.invalidateQueries(queryKeys.SHARE_VALUES)
            reset()
        } catch (err) {
            console.log(err)
            alert("Algo salio mal")
        }
    }

    return (
        <SettingsSectionLayout title="Valor del acción" subtitle="Puedes configurar el valor del acción">
            <form className="grid gap-4" onSubmit={handleSubmit(onSubmit)}>
                <Input label="A partir del" name="from" type="date" ref={register({ required: true })} error={errors.from} />
                <Input label="Valor en Euros" name="valueEuro" step=".01" type="number" ref={register({ required: true })} error={errors.valueCop} />
                <Input label="Valor en Pesos" name="valueCOP" step=".01" type="number" ref={register({ required: true })} error={errors.valueEur} />
                <div className="flex justify-end space-x-2">
                    <Button type="submit">Actualizar</Button>
                </div>
            </form>
            <div className="mt-4">
                <ShareValuesList shareValues={shareValues} />
            </div>

        </SettingsSectionLayout >
    )

}

export default ShareValueConfig