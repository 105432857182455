import Tab from "./Tab"

export interface TabOption {
    label: string,
    id: string | number
}

interface TabsProps {
    options: Array<TabOption>
    selectedTab: string | number
    onTabChange: (id: string | number) => void
}

const Tabs = ({ options, selectedTab, onTabChange }: TabsProps) => {

    return (
        <div>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">Select a tab</label>
                <select id="tabs" name="tabs" onChange={(e) => onTabChange(e.target.value)} className="block w-full focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md">
                    {
                        options?.map(option => <option key={option.id} value={option.id} selected={option.id === selectedTab} >{option.label}</option>)
                    }
                </select>
            </div>
            <div className="hidden sm:block">
                <nav className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
                    {
                        options?.map((option, index) => (
                            <Tab
                                key={option.id}
                                isfirst={index === 0}
                                isLast={index === options.length - 1}
                                option={option}
                                selected={option.id === selectedTab}
                                onSelect={onTabChange} />
                        ))
                    }
                </nav>
            </div>
        </div >
    )

}

export default Tabs