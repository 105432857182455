import queryKeys from "config/queries"
import axios from "helpers/axios"
import { useQueryClient } from "react-query"

const useEditSellMotive = (id: string) => {

    const queryClient = useQueryClient()

    const enableOrDisable = async (enabled: boolean) => {
        try {
            await axios.patch(`/sell-motives/${id}`, { enabled })
            queryClient.invalidateQueries(queryKeys.SELL_MOTIVES)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    const removeRequiredDocumentType = () => {

    }
    const addRequiredDocumentType = () => {

    }

    return { removeRequiredDocumentType, addRequiredDocumentType, enableOrDisable }

}

export default useEditSellMotive