import axios from "helpers/axios";
import Button from "components/Button/Button";
import Input from "components/Input/Input";
import Toggle from "components/Toggle/Toggle";
import { useForm, Controller } from "react-hook-form";
import DocumentType from "types/DocumentType";
import SettingsSectionLayout from "../SettingsSectionLayout";
import { useQuery } from "react-query";
import queryKeys from "config/queries";
import { useState } from "react";
import { useQueryClient } from "react-query";
import SellMotiveList from "./components/SellMotiveList";
import useCreateSellMotive from "hooks/useCreateSellMotive";
import { notifySuccess } from "components/Toast/ToastNotify";

const SellMotives = () => {
  const { documentTypes, createSellMotive } = useCreateSellMotive();

  const { data: sellMotives } = useQuery([queryKeys.SELL_MOTIVES], () =>
    axios.get<Array<DocumentType>>(`/sell-motives`).then((res) => res.data)
  );

  const [selectedDocumentTypeIds, setSelectedDocumentTypeIds] = useState<
    Array<string>
  >([]);

  const { register, handleSubmit, errors, reset, control } = useForm({
    reValidateMode: "onChange",
  });

  const onSubmit = (data: any) => {
    createSellMotive({
      ...data,
      requiredDocumentTypes: selectedDocumentTypeIds,
    });
    notifySuccess("El motivo de venta a sido creado")
    reset();
  };

  const onSelectOrUnselectDocumentType = (enabled: boolean, id: string) => {
    if (enabled) {
      setSelectedDocumentTypeIds((selectedDocumentTypeIds) => [
        ...selectedDocumentTypeIds,
        id,
      ]);
    } else {
      setSelectedDocumentTypeIds((selectedDocumentTypeIds) =>
        selectedDocumentTypeIds.filter((selectedId) => selectedId !== id)
      );
    }
  };

  return (
    <SettingsSectionLayout
      title="Motivos de venta anticipada"
      subtitle="Define los motivos por los cuales un accionista puede vender sus acciones antes de 5 años"
    >
      <form className="grid gap-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-4 md:grid-cols-2">
          <Input
            label="Nombre del motivo"
            name="name"
            type="text"
            placeholder="Nacimiento de un hijo"
            ref={register({ required: true })}
            error={errors.name}
          />
          <Input
            label="Codigo del motivo (Decathlon united)"
            name="code"
            type="text"
            placeholder="Codigo del motivo"
            ref={register({ required: true })}
            error={errors.code}
          />
          <Input
            label="Descripción y requerimientos adicionales"
            name="description"
            type="textarea"
            placeholder="Descripción del motivo"
            ref={register({ required: true })}
            error={errors.code}
          />
        </div>
        <Controller
          control={control}
          defaultValue={false}
          name="totalSell"
          render={({ value, onChange }) => (
            <Toggle
              leftLabel="Venta total"
              enabled={value}
              onChange={onChange}
            />
          )}
        />

        <div className="block text-sm font-medium text-gray-700">
          Documentos requeridos para vender bajo para este motivo:
        </div>
        <div className="grid gap-4 sm:grid-cols-2">
          {documentTypes &&
            documentTypes.map((documentType) => (
              <Toggle
                enabled={
                  selectedDocumentTypeIds.filter((id) => id == documentType._id)
                    .length > 0
                }
                rightLabel={documentType.name}
                onChange={(enabled) =>
                  onSelectOrUnselectDocumentType(enabled, documentType._id)
                }
              />
            ))}
        </div>
        <div className="flex justify-end">
          <Button type="submit">Crear</Button>
        </div>
      </form>
      <div className="pt-8 mt-8 border-t">
        <SellMotiveList sellMotives={sellMotives} />
      </div>
    </SettingsSectionLayout>
  );
};

export default SellMotives;
