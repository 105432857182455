/* This example requires Tailwind CSS v2.0+ */
import { useState } from 'react'
import { Switch } from '@headlessui/react'
import clsx from 'clsx'


interface ToggleProps {
    enabled: boolean
    onChange: (enabled: boolean) => void
    rightLabel?: string
    leftLabel?: string
}

const Toggle = ({ enabled, onChange, rightLabel, leftLabel }: ToggleProps) => {



    return (
        <Switch.Group as="div" className="flex items-center">
            {
                leftLabel &&
                <Switch.Label as="span" className="mr-3">
                    <span className="text-sm font-medium text-gray-900">{leftLabel}</span>
                </Switch.Label>
            }
            <Switch
                checked={enabled}
                onChange={onChange}
                className={clsx(
                    enabled ? 'bg-blue-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                )}
            >
                <span className="sr-only">{leftLabel}{rightLabel}</span>
                <span
                    aria-hidden="true"
                    className={clsx(
                        enabled ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                />
            </Switch>
            {
                rightLabel &&
                <Switch.Label as="span" className="ml-3">
                    <span className="text-sm font-medium text-gray-900">{rightLabel}</span>
                </Switch.Label>
            }
        </Switch.Group>
    )
}


export default Toggle