import Menu from "components/Navigation/Menu/Menu"
import UserMenuFooter from "components/Sidebar/SidebarUserFooter"
import logo from "assets/logos/shareholding_logo.png"

const Sidebar = () => {

    return (
        <div className="flex flex-col h-0 flex-1 border-r border-gray-200 bg-white">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                <div className="flex justify-center items-center flex-shrink-0 px-4">
                    <img className="w-1/2" src={logo} alt="logo Decathlon" />
                </div>
                <Menu />
            </div>
            <UserMenuFooter />
        </div>
    )

}

export default Sidebar