import { DownloadIcon, InformationCircleIcon, UploadIcon } from "@heroicons/react/outline"
import Button from "components/Button/Button"
import Modal from "components/Modal/Modal"
import queryKeys from "config/queries"
import useUploadExcel from "hooks/useUploadExcel"
import { useForm } from "react-hook-form"
import { useQueryClient } from "react-query"

interface UploadShareholdersModalProps {
    onClose: () => void
    visible: boolean
}

const UploadShareholdersModal = ({ visible, onClose }: UploadShareholdersModalProps) => {


    const { register, handleSubmit } = useForm()

    const queryClient = useQueryClient()
    const { uploadExcelFile, isLoading } = useUploadExcel("/shareholders/massive", () => {
        queryClient.invalidateQueries(queryKeys.SHAREHOLDERS)
        onClose()
    })

    const onSubmit = (data: any) => uploadExcelFile(data.files[0])

    const handleDownloadTemplate = () => {
        window.open(`${process.env.REACT_APP_API_URL}/templates/shareholders_template.xlsx`, "_blank")
    }

    return (
        <Modal visible={visible} onClose={onClose}>
            <div className="flex flex-col space-y-4 w-full">
                <h1 className="font-medium">Crear accionista</h1>
                <div className="rounded-md bg-blue-50 p-4 w-full">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3 text-left text-sm text-blue-700">
                            <p>Descargar el template excel y llenarlo con la información de los accionistas.</p><p>Si un accionista ya existe, se actualiza su perfil.</p>
                        </div>
                    </div>
                    <div></div>
                </div>
                <div className="flex justify-center w-full">
                    <Button onClick={handleDownloadTemplate}><DownloadIcon className="h-5 mr-2" />Descargar el template</Button>
                </div>
                <div className="w-full border-t border-gray-300" />
                <form className="flex flex-col space-y-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex items-center space-x-2 p-2 border border-gray-300 rounded-md shadow-sm">
                        <svg className="flex-shrink-0 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                        </svg>
                        <input
                            ref={register({ required: true })}
                            name="files"
                            type="file"
                        />
                    </div>
                    <div className="flex justify-end w-full space-x-2">
                        <Button variant="secondary" disabled={isLoading} onClick={onClose}>Cancelar</Button>
                        <Button isLoading={isLoading} type="submit"><UploadIcon className="h-5 mr-2" />Subir y crear los accionistas</Button>
                    </div>
                </form>
            </div>
        </Modal >
    )

}

export default UploadShareholdersModal