import transactionStates from "config/transactionStates"
import axios from "helpers/axios"
import { useMutation } from "react-query"
import { base64ToFile } from "utils/functions"

interface DownloadReportFilters {
    from: string,
    to: string,
    query: string
}

const useDownloadTransactionsReport = () => {
    const { mutate: downloadBuyReport, isLoading: isLoadingBuyReport } = useMutation(() => axios.get("/transactions/buy-report", { responseType: 'blob' }).then(res => res.data), {
        onSuccess: (reportURL) => {
            // window.open(`${process.env.REACT_APP_API_URL}${reportURL}`, "_blank")
            base64ToFile(reportURL, "Compras-para-enviar.xls");
        }
    })

    const { mutate: downloadSellReport, isLoading: isLoadingSellReport } = useMutation(() => axios.get("/transactions/sell-report", { responseType: 'blob' }).then(res => res.data), {
        onSuccess: (reportURL) => {
            // window.open(`${process.env.REACT_APP_API_URL}${reportURL}`, "_blank")
            base64ToFile(reportURL, "Ventas-para-enviar.xls");
        }
    })

    const { mutate: downloadTransactionsReport, isLoading: isLoadingTransactionsReport } = useMutation((query: string) => axios.get(`/transactions/report${query}`, { responseType: 'blob' }).then(res => res.data), {
        onSuccess: (reportURL) => {
            // window.open(`${process.env.REACT_APP_API_URL}${reportURL}`, "_blank")
            base64ToFile(reportURL, "transacciones-finalizadas.xls");
        }
    })

    const { mutate: downloadTransactionsReport_II, isLoading: isLoadingTransactionsReport_II } = useMutation((query: string) => axios.get(`/transactions/report${query}`, { responseType: 'blob' }).then(res => res.data), {
        onSuccess: (reportURL) => {
            // window.open(`${process.env.REACT_APP_API_URL}${reportURL}`, "_blank")
            base64ToFile(reportURL, "transacciones-pendientes.xls");
        }
    })

    const downloadReport = (reportType: string) => {
        if (reportType == "debit") {
            downloadSellReport()
            return
        }
        if (reportType == "accepted") {
            downloadTransactionsReport("?state=accepted")
            return
        }
        if (reportType == "pending") {
            downloadTransactionsReport_II(`?state=${transactionStates.ACCEPTED_LOCALLY}&state=${transactionStates.PENDING_ADMIN}&state=${transactionStates.PENDING_SHAREHOLDER}&state=${transactionStates.PENDING_PAYMENT}`)
            return
        }
        downloadBuyReport()
    }

    return { downloadReport, isLoadingReport: isLoadingBuyReport || isLoadingSellReport || isLoadingTransactionsReport || isLoadingTransactionsReport_II }

}

export default useDownloadTransactionsReport