import Transaction from "types/Transaction"
import TransactionListItem from "./TransactionListItem"

interface TransactionListProps {
    transactions: Array<Transaction> | undefined
}

const TransactionList = ({ transactions }: TransactionListProps) => {

    // handle is loading and empty list

    if (transactions && transactions.length == 0) return <p className="text-center mt-8 text-gray-600 bg-transparent">No hay transacciones para este filtro</p>

    return (
        <div className="bg-white shadow overflow-hidden rounded-md">
            <ul className="divide-y divide-gray-200">
                {
                    transactions?.map(transaction => <TransactionListItem transaction={transaction} />)
                }
            </ul>
        </div>

    )

}

export default TransactionList