import axios from "helpers/axios"
import { useQuery, useQueryClient } from "react-query"
import Transaction from "types/Transaction"
import TransactionStateUpdate from "types/TransactionStateUpdate"

const useTransaction = (id: string) => {

    const { data: transaction, isLoading } = useQuery([id], () => axios.get<Transaction>(`/transactions/${id}`).then(res => res.data), { enabled: !!id })

    const queryClient = useQueryClient()
    const updateTransactionState = async (state: TransactionStateUpdate) => {
        try {
            await axios.post(`/transactions/${id}/state-updates`, state)
            queryClient.invalidateQueries(id)
        } catch {

        }
    }

    return { transaction, isLoading, updateTransactionState }
}

export default useTransaction