import queryKeys from "config/queries"
import axios from "helpers/axios"
import { useQuery } from "react-query"
import BAEListItem from "./BAEListItem"

interface BAEListProps {

}

const BAEList = ({ }: BAEListProps) => {

    const { data: baes } = useQuery(queryKeys.BAE, () => axios.get(`/bae`).then(res => res.data))

    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Accionista
                                            </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Candidad de acciones
                                            </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Acceptado
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Fecha de acceptación
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">

                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {
                                    baes?.map((bae: any) => <BAEListItem key={bae._id} bae={bae} />)
                                }
                            </tbody>
                        </table>
                        {
                            !baes && <p className=" p-4 text-gray-500 text-center">No hay accionistas todavía</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}

export default BAEList