import queryKeys from "config/queries"
import axios from "helpers/axios"
import { useQueryClient } from "react-query"

const useEditUserAccess = (id: string) => {

    const queryClient = useQueryClient()

    const removeUserAccess = async () => {
        try {
            await axios.delete(`/users/${id}`)
            queryClient.invalidateQueries(queryKeys.DASHBOARD_USERS)
        } catch (err) {
            throw err
        }
    }

    return { removeUserAccess }
}

export default useEditUserAccess