enum routes {
    HOME = "/",
    TRANSACTIONS = "/transactions",
    TRANSACTION_DETAILS = "/transactions/:id",
    SETTINGS = "/settings",
    SHAREHOLDERS = "/shareholders",
    BAE = "/bae",
    EXCHANGERATE= "/exchangeRate"
}

export default routes