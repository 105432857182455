import Button from "components/Button/Button"
import Input from "components/Input/Input"
import transactionStates from "config/transactionStates"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import TransactionStateUpdate from "types/TransactionStateUpdate"





interface TransactionStateProps {
    onUpdateState: (stateUpdate: TransactionStateUpdate) => Promise<void>
}

const TransactionState = ({ onUpdateState }: TransactionStateProps) => {

    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm()

    const onSubmit = async (data: any) => {
        try {
            await onUpdateState(data)
            reset()
        } catch (err) {
            alert("Algo salió mal")
        }
    }

    return (
        <form className="grid gap-4" onSubmit={handleSubmit(onSubmit)}>
            <div>
                <label htmlFor="state" className="mb-1 block text-sm font-medium text-gray-700">Acción</label>
                <select className="w-full rounded-lg border-gray-300 shadow-sm" ref={register({ required: true })} name="state">
                    <option value={transactionStates.ACCEPTED_LOCALLY}>Aceptar</option>
                    <option value={transactionStates.PENDING_SHAREHOLDER}>Solicitar información</option>
                    <option value={transactionStates.ACCEPTED}>Finalizar</option>
                    <option value={transactionStates.REJECTED}>Rechazar</option>
                </select>
            </div>
            <Input
                label="Comentario"
                type="textarea"
                ref={register({ required: watch("state") === transactionStates.PENDING_SHAREHOLDER })}
                error={errors.comment}
                name="comment" />
            <div className="flex justify-end">
                <Button type="submit">Actualizar</Button>
            </div>
        </form >
    )

}

export default TransactionState