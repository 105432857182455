import { formatDateToString } from "helpers"
import useEditAnnouncement from "hooks/useEditAnnouncement"
import Announcement from "types/Announcement"

interface AnnoucementListItemProps {
    announcement: Announcement
}

const AnnoucementListItem = ({ announcement }: AnnoucementListItemProps) => {

    const { deleteAnnouncement } = useEditAnnouncement(announcement._id)

    return (
        <li className="py-4">
            <div className="flex justify-between">
                <h3 className="text-sm text-gray-800 font-medium">{announcement.title}</h3>
                <button className="focus:outline-none" onClick={deleteAnnouncement}>
                    <svg className="h-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                </button>
            </div>
            <p className="text-sm text-gray-600">{announcement.text}</p>
            <p className="text-sm italic text-gray-400 mt-1">{formatDateToString(announcement.createdAt)}</p>
        </li>
    )

}

export default AnnoucementListItem