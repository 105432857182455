enum transactionStates {
    PENDING_PAYMENT = "pending_payment",
    PENDING_SHAREHOLDER = "pending_shareholder",
    PENDING_ADMIN = "pending_admin",
    ACCEPTED_LOCALLY = "accepted_locally",
    ACCEPTED = "accepted",
    REJECTED = "rejected",
    CANCELLED = "cancelled"
}

export default transactionStates
