
import MainLayout from "components/Layout/MainLayout"
import useSession from "hooks/useSession"

const Login = () => {

    const { login } = useSession()

    return (
        <div className="min-h-screen bg-gray-900 flex flex-col justify-center items-center">
            <MainLayout>
                <h1 className="text-4xl lg:text-9xl font-bold text-white">Dashboard <br /><span className="bg-gradient-to-tr from-blue-700 to-cyan-700 text-transparent bg-clip-text">Accionariado</span></h1>
                <div className="w-full flex justify-end mt-5">
                    <button
                        onClick={login}
                        className="text-sm lg:text-base px-4 py-4 flex items-center font-medium rounded-lg bg-gray-800 text-white focus:outline-none">
                        Ingresar
                        <svg className="h-5 ml-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
                            <path fillRule="evenodd" d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
                        </svg>
                    </button>

                </div>
            </MainLayout>
        </div >
    )

}

export default Login