import Card from "components/Card/Card"
import MainLayout from "components/Layout/MainLayout"
import PageHeader from "components/PageHeader/PageHeader"
import routes from "config/routes"
import axios from "helpers/axios"
import { useQuery } from "react-query"
import { useParams } from "react-router"
import Transaction from "types/Transaction"
import TransactionSummary from "./components/TransactionSummary/TransactionSummary"
import TransactionTimeline from "./components/TransactionTimeline/TransactionTimeline"
import TransactionState from "./components/TransactionState"
import transactionStates from "config/transactionStates"
import useTransaction from "hooks/useTransaction"

interface TransactionDetailsRouteParams {
    id: string
}

const TransactionDetails = () => {

    const { id } = useParams<TransactionDetailsRouteParams>()

    const { transaction, updateTransactionState } = useTransaction(id)

    return (
        <MainLayout>
            <PageHeader title={`Transacción n° ${id}`} breadcrumb={[{ label: "Transacciones", path: routes.TRANSACTIONS }]} />
            <div className="mt-4 grid lg:grid-cols-5 gap-4">
                <div className=" lg:col-span-3">
                    <Card title="Información de la transacción">
                        <TransactionSummary transaction={transaction} />
                    </Card>
                </div>
                <div className="lg:col-span-2 grid gap-4">
                    {
                        !(transaction?.state === transactionStates.REJECTED || transaction?.state === transactionStates.ACCEPTED || transaction?.state === transactionStates.CANCELLED) && <Card title="Cambiar el estado" subtitle="Solicita documentos adicionales">
                            <TransactionState
                                onUpdateState={updateTransactionState} />
                        </Card>
                    }

                    <Card title="Historial de la transación" subtitle="Cada cambio de estado de la transación">
                        <TransactionTimeline events={transaction?.stateUpdates} />
                    </Card>

                </div>
            </div>

        </MainLayout >
    )

}

export default TransactionDetails