/* This example requires Tailwind CSS v2.0+ */
import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import clsx from 'clsx'

interface RadioGroupOption {
    id: string
    name: string,
    description: string
}


interface CustomRadioGroupProps {
    options: Array<RadioGroupOption>
    onChange: (value: string) => void
    value: string
    label?: string
}

export default function CustomRadioGroup({ options, onChange, value, label }: CustomRadioGroupProps) {

    return (
        <RadioGroup value={value} onChange={onChange}>
            <RadioGroup.Label className="block text-sm font-medium text-gray-700">{label}</RadioGroup.Label>
            <div className="space-y-4 mt-2">
                {options.map((option) => (
                    <RadioGroup.Option
                        key={option.name}
                        value={option.id}
                        className={({ active }) =>
                            clsx(
                                active ? 'ring-1 ring-offset-2 ring-blue-500' : '',
                                'relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none'
                            )
                        }
                    >
                        {({ checked }) => (
                            <>
                                <div className="flex items-center">
                                    <div className="text-sm">
                                        <RadioGroup.Label as="p" className="font-medium text-gray-900">
                                            {option.name}
                                        </RadioGroup.Label>
                                        <RadioGroup.Description as="div" className="text-gray-500">
                                            <p>{option.description}</p>
                                        </RadioGroup.Description>
                                    </div>
                                </div>
                                <div
                                    className={clsx(
                                        checked ? 'border-blue-500' : 'border-transparent',
                                        'absolute -inset-px rounded-lg border-2 pointer-events-none'
                                    )}
                                    aria-hidden="true"
                                />
                            </>
                        )}
                    </RadioGroup.Option>
                ))}
            </div>
        </RadioGroup>
    )
}
