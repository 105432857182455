
interface ForbiddenProps {

}

const Forbidden = ({ }: ForbiddenProps) => {

    return (
        <div className="min-h-screen flex items-center justify-center">Forbidden</div>
    )

}

export default Forbidden