export function base64ToFile(data: any, filename: string){
    const url = window.URL.createObjectURL(new Blob([data],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
}

export function base64ToDocument(data: string, name:string){
    const downloadLink = document.createElement('a')
    downloadLink.href = 'data:application/octet-stream;base64,' + data
    downloadLink.download = name
    downloadLink.click()
    downloadLink.remove()
}