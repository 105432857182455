import { useHistory } from "react-router-dom"

import MainLayout from "components/Layout/MainLayout"
import PageHeader from "components/PageHeader/PageHeader"
import { useEffect, useState } from "react"
import SettingMenu from "./components/SettingsMenu/SettingsMenu"
import Announcements from "./components/SettingsSections/Announcement/Announcements"
import BuyingPeriod from "./components/SettingsSections/BuyingPeriod/BuyingPeriodConfig"
import DocumentTypes from "./components/SettingsSections/DocumentTypes/DocumentTypes"
import SellMotives from "./components/SettingsSections/SellMotives/SellMotives"
import ShareValue from "./components/SettingsSections/ShareValue/ShareValueConfig"
import UserAccesses from "./components/SettingsSections/UserAccesses/UserAccesses"
import routes from "config/routes"

export interface SettingsSection {
    title: string
    id: string
    icon: React.ReactNode
    component: React.ReactNode
}

const options: Array<SettingsSection> = [
    {
        title: "Anuncios",
        id: "announcements",
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
        </svg>,
        component: <Announcements />
    },
    {
        title: "Valor del Acción",
        id: "share-value",
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
        </svg>,
        component: <ShareValue />
    },
    {
        title: "Periodos de compra",
        id: "buying-period",
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
        </svg>,
        component: <BuyingPeriod />
    },
    {
        title: "Tipos de documentos",
        id: "document-types",
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>,
        component: <DocumentTypes />
    },
    {
        title: "Motivos de venta",
        id: "sell-motive",
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
        </svg>,
        component: <SellMotives />
    },
    {
        title: "Accesos",
        id: "users",
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
        </svg>,
        component: <UserAccesses />
    }
]


const Settings = () => {

    const [selectedSection, setSelectedSection] = useState<SettingsSection>(options[0])

    const history = useHistory()

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const selectedMenu = params.get('selected');
        console.log(selectedMenu)
        if (selectedMenu) {
            const selectedSection: SettingsSection | undefined = options.filter(option => option.id == selectedMenu)[0]
            selectedSection && setSelectedSection(selectedSection)
        }
    }, [])

    useEffect(() => {
        history.push({
            pathname: routes.SETTINGS,
            search: '?selected=' + selectedSection.id
        })
    }, [selectedSection])

    return (
        <div>
            <MainLayout>

                <div className="grid gap-4">
                    <PageHeader title="Ajustes de la plataforma" />
                    <div className="bg-white rounded-lg shadow overflow-hidden">
                        <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                            <aside className="py-6 lg:col-span-3">
                                <SettingMenu options={options} selectedSection={selectedSection} onSectionChange={(section) => setSelectedSection(section)} />
                            </aside>
                            <div className="divide-y divide-gray-200 lg:col-span-9">
                                {selectedSection.component}
                            </div>
                        </div>
                    </div>
                </div>
            </MainLayout>
        </div >
    )

}

export default Settings