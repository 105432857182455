import { Link } from "react-router-dom";
import clsx from "clsx";

interface MenuItemProps {
    icon?: React.ReactNode
    label: string
    isCurrent?: boolean
    to: string
}

const MenuItem = ({ label, isCurrent = false, to, icon }: MenuItemProps) => {

    const containerClassNames = clsx(
        "group flex items-center px-2 py-2 text-base md:text-sm font-medium rounded-md ",
        isCurrent
            ? "bg-gray-100 text-gray-900"
            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900"
    )

    const iconClassNames = clsx(
        "mr-4 md:mr-3 h-6 w-6 ",
        isCurrent
            ? "text-gray-500"
            : "text-gray-400 group-hover:text-gray-500"
    )

    return (
        <Link to={to} className={containerClassNames}>
            <div className={iconClassNames}>
                {icon}
            </div>
            {label}
        </Link>
    )

}

export default MenuItem