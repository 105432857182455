import Announcement from "types/Announcement"
import AnnoucementListItem from "./AnnouncementListItem"

interface AnnouncementListProps {
    announcements: Array<Announcement>
}

const AnnouncementList = ({ announcements }: AnnouncementListProps) => {

    return (
        <ul className="divide-y divide-gray-200">
            {
                announcements?.map(announcement => <AnnoucementListItem key={announcement._id} announcement={announcement} />)
            }
        </ul>
    )

}

export default AnnouncementList