import { formatDateToString } from "helpers"
import useDeleteShareValue from "hooks/useDeleteShareValue"
import ShareValue from "types/ShareValue"

interface ShareValueListItemProps {
    shareValue: ShareValue
    nextShareValue: ShareValue | undefined
    lastShareValue: ShareValue | undefined
}

const ShareValueListItem = ({ shareValue, nextShareValue, lastShareValue }: ShareValueListItemProps) => {

    const deleteShareValue = useDeleteShareValue(shareValue._id)

    const icon = lastShareValue
        ? shareValue.valueEuro > lastShareValue?.valueEuro
            ? <svg className="h-5 ml-2 text-green-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
            : <svg className="h-5 ml-2 text-red-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z" clipRule="evenodd" />
            </svg>
        : <svg className="h-5 ml-2 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z" clipRule="evenodd" />
        </svg>

    return (
        <div className="p-2 grid lg:grid-cols-2 text-gray-800">

            <div className="flex items-center text-gray-600 text-sm md:text-base">
                <div>
                    {formatDateToString(shareValue.from)}
                </div>
                <svg className="h-5 mx-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
                <div>
                    {nextShareValue ? formatDateToString(nextShareValue.from) : "Hoy"}
                </div>
            </div>
            <div className="flex items-center justify-between lg:justify-end">

                <p>{shareValue.valueEuro}€<span> - {shareValue.valueCOP}$</span></p>
                {icon}
                <button onClick={deleteShareValue} className="focus:outline-none text-gray-400 ml-2 flex items-center">
                    <svg className="h-4 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                    </svg>
                </button>
            </div>

        </div>
    )

}

export default ShareValueListItem