import { error } from "console";
import { useMutation } from "react-query";
import axiosApiInstance from "../helpers/axios";


const useUploadExcel = (apiPath: string, onUploaded?: Function) => {

    const { mutate, isLoading, error } = useMutation((data: FormData) => axiosApiInstance.post(apiPath, data, {
        headers: {
            "content-Type": "multipart/form-data"
        }
    }), {
        onSuccess: () => onUploaded && onUploaded()
    })

    const uploadExcelFile = async (file: File) => {
        if (!file) return
        const formData = new FormData()
        formData.append("file", file)
        mutate(formData)
    }

    return { uploadExcelFile, isLoading, error }

    // const uploadSharesInformationExcel = async (file: File) => {
    //     const formData = new FormData()
    //     formData.append("file", file)
    //     try {
    //         const response = await axiosApiInstance.post(`/shareholders/massive-shareholders`, formData, {
    //             headers: {
    //                 "content-type": "multipart/form-data"
    //             }
    //         })
    //         console.log(response);
    //     }
    //     catch (err) {
    //         throw err
    //     }
    // }

}

export default useUploadExcel
