import roles from "config/roles"
import useSession from "hooks/useSession"
import React from "react"
import { Redirect, Route } from "react-router-dom"
import Forbidden from "../Forbidden"

interface ProtectedRouteProps {
    exact: boolean;
    path: string
    component: React.FunctionComponent
    requiredRoles?: Array<String>
}

const ProtectedRoute = ({ exact = true, path, component: Component, requiredRoles }: ProtectedRouteProps) => {

    const { currentUser } = useSession()

    return (
        <Route
            exact={exact}
            path={path}
            render={() => {
                if (!currentUser) return <div>Not loggedIn</div>
                if (requiredRoles && !requiredRoles.includes(currentUser.role)) return <Forbidden />
                return <Component />
            }}
        />
    )

    // return (
    //     <Route exact={exact} path={path}>
    //         {
    //             isLoggedIn
    //                 ? (!requiredRoles || (requiredRoles && requiredRoles.includes(role))) ? <Component /> : <Forbidden />
    //                 : <Forbidden />
    //         }
    //     </Route>
    // )

}

export default ProtectedRoute