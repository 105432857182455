import axios from "helpers/axios"
import Button from "components/Button/Button"
import Input from "components/Input/Input"
import { useForm } from "react-hook-form"
import { useQuery, useQueryClient } from "react-query"
import SettingsSectionLayout from "../SettingsSectionLayout"
import BuyingPeriodList from "./BuyingPeriodList"
import queryKeys from "config/queries"
import { HTMLInputDateToDate } from "helpers"


const BuyingPeriodConfig = () => {

    const queryClient = useQueryClient()

    const { data: buyingPeriods } = useQuery([queryKeys.BUYING_PERIODS], () => axios.get("/buying-periods").then(res => res.data))

    const { register, errors, handleSubmit, reset } = useForm({
        reValidateMode: "onBlur"
    })


    const onSubmit = async (data: any) => {
        try {
            await axios.post("/buying-periods", {
                from: HTMLInputDateToDate(data.from),
                to: HTMLInputDateToDate(data.to)
            })
            queryClient.invalidateQueries(queryKeys.BUYING_PERIODS)
            reset()
        } catch (err) {
            console.log(err)
        }
    }


    return (
        <SettingsSectionLayout title="Periodos de compra" subtitle="Define la fecha del proximo periodo de compra">
            <form className="grid gap-4" onSubmit={handleSubmit(onSubmit)}>
                <div className="grid gap-4 grid-cols-2">
                    <Input label="Desde" name="from" type="date" ref={register({ required: "Ingresar una fecha de inicio" })} error={errors.from?.message} />
                    <Input label="Hasta" name="to" type="date" ref={register({ required: "Ingresar una fecha de fin" })} error={errors.to?.message} />
                </div>
                <div className="flex justify-end space-x-2">
                    <Button type="submit">Programar el periodo de compra</Button>
                </div>
            </form>
            <div className="mt-4">
                <BuyingPeriodList buyingPeriods={buyingPeriods} />
            </div>
        </SettingsSectionLayout>
    )

}

export default BuyingPeriodConfig