/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { HomeIcon, IdentificationIcon, MailIcon, TrendingUpIcon, XIcon, CurrencyEuroIcon } from '@heroicons/react/outline'
import { useQuery } from 'react-query'
import axios from 'helpers/axios'
import Shareholder from 'types/Shareholder'
import TransactionList from 'pages/Transactions/components/TransactionList'
import Transaction from 'types/Transaction'
import { formatNumberToEuroCurrencyString } from 'helpers'
import { Toaster } from 'react-hot-toast';
import { notifySuccess, notifyError} from 'components/Toast/ToastNotify'
import ModalAction from 'components/Modal/ModalAction'
//import queryKeys from "config/queries"

interface ShareholderDetailsSlideOver {
    shareholderId: string | undefined,
    onClose: () => void,
}

const ShareholderDetailsSlideOver = ({ shareholderId, onClose }: ShareholderDetailsSlideOver) => {

    const { data: transactions, refetch: transactionsUpdate } = useQuery(["generaltrans", shareholderId], () => axios.get<Array<Transaction>>(`/shareholders/${shareholderId}/generaltrans`).then(res => res.data), { enabled: !!shareholderId })

    const { data: shareholderSummary, refetch: summaryUpdate } = useQuery(["summary", shareholderId], () => axios.get<Shareholder>(`/shareholders/${shareholderId}/summary`).then(res => res.data), { enabled: !!shareholderId })

    const [showModalAction, setShowModalAction] = useState(false)

    //console.log(shareholderSummary)

    //Abre la modal para aceptar la venta de acciones
    const sellSharesModal = () => {
        setShowModalAction(true)
    }

    const onSubmit = async () => {
        try {
            const res = await axios.post<any>(`/transactions/sell/${shareholderId}`)
            console.log(res)
            setShowModalAction(false)
            notifySuccess("Acciones vendidas, en espera de aceptación")
            transactionsUpdate()
            summaryUpdate()
        } catch (error) {
            console.log(error)
            setShowModalAction(false)
            notifyError("Posiblemente no hay acciones para vender")
        }  
    }

    //Modal de confirmacion
    return (
        <Transition.Root show={!!shareholderId} as={Fragment}>
            <Dialog as="div" static className="fixed inset-0 overflow-hidden" open={!!shareholderId} onClose={onClose}>
                <div className="absolute inset-0 overflow-hidden">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="w-screen max-w-2xl">
                                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                                    <div className="px-4 sm:px-6">
                                        <div className="flex items-start justify-between">
                                            <Dialog.Title className="text-lg font-medium text-gray-900">Portafolio de {shareholderSummary?.shareholder.fullName}</Dialog.Title>
                                            <div className="ml-3 h-7 flex items-center">
                                                <button
                                                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                    onClick={onClose}
                                                >
                                                    <span className="sr-only">Close panel</span>
                                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-6 relative flex-1 px-4 sm:px-6 space-y-6">
                                        {/* content */}
                                        <div className="text-gray-600 space-y-2 border-l-4 border-blue-500 pl-2">
                                            <div className="flex items-center">
                                                <IdentificationIcon className="h-5 mr-2" />{shareholderSummary?.shareholder.document?.type} {shareholderSummary?.shareholder.document?.number}
                                            </div>
                                            <div className="flex items-center">
                                                <HomeIcon className="h-5 mr-2" />{shareholderSummary?.shareholder.address?.street}
                                            </div>
                                            <div className="flex items-center">
                                                <MailIcon className="h-5 mr-2" />{shareholderSummary?.shareholder.professionalEmail}
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <TrendingUpIcon className="h-10 mr-2 text-blue-500" />
                                            <p className="font-bold text-4xl text-gray-800">{formatNumberToEuroCurrencyString(shareholderSummary?.shares.totalEuro)}</p>
                                            <button onClick={sellSharesModal} 
                                                type="button" className="inline-flex items-center mx-5 px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                                <CurrencyEuroIcon className="h-5 mr-2" />Vender acciones</button>
                                        </div>
                                        <div className="md:space-x-2 flex flex-col md:flex-row space-y-2 md:space-y-0 w-full">
                                        </div>
                                        <div>
                                            <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
                                                <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                                                    <dt className="text-sm font-medium text-gray-500 truncate">Acciones</dt>
                                                    <dd className="mt-1 text-3xl font-semibold text-gray-900">{shareholderSummary?.shares.total}</dd>
                                                </div>
                                                <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                                                    <dt className="text-sm font-medium text-gray-500 truncate">Compradas</dt>
                                                    <dd className="mt-1 text-3xl font-semibold text-gray-900">{shareholderSummary?.shares.bought}</dd>
                                                </div>
                                                <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                                                    <dt className="text-sm font-medium text-gray-500 truncate">Vendidas</dt>
                                                    <dd className="mt-1 text-3xl font-semibold text-gray-900">{shareholderSummary?.shares.sold}</dd>
                                                </div>
                                            </dl>
                                            <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 mt-4">
                                                <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                                                    <dt className="text-sm font-medium text-gray-500 truncate">Recibidas por BAE</dt>
                                                    <dd className="mt-1 text-3xl font-semibold text-gray-900">{shareholderSummary?.shares.bae}</dd>
                                                </div>
                                                <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                                                    <dt className="text-sm font-medium text-gray-500 truncate">Recibidas por TOP UP</dt>
                                                    <dd className="mt-1 text-3xl font-semibold text-gray-900">{shareholderSummary?.shares.topUp}</dd>
                                                </div>
                                            </dl>
                                        </div>
                                        <p className="text-lg leading-6 font-medium text-gray-900">Transacciones</p>
                                        <TransactionList transactions={transactions} />
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                        <ModalAction title={"Venta de acciones"} subTitle={"Desea vender sus acciones?"} isVisible={showModalAction} onClose={setShowModalAction} onSubmit={onSubmit}/>
                        <Toaster />
                    </div>
                </div>
            </Dialog >
        </Transition.Root >
    )
}

export default ShareholderDetailsSlideOver