import DocumentList from "components/DocumentList/DocumentList"
import TransactionSummaryItem from "./TransactionSummaryItem"

import Transaction from "types/Transaction"
import { formatDateTimeToString, formatNumberToPesosCurrencyString, formatNumberToEuroCurrencyString } from "helpers"
import { CheckIcon, XIcon } from "@heroicons/react/outline"
import clsx from "clsx"
import transactionTypes from "config/transactionTypes"

interface TransactionSummaryProps {
    transaction: Transaction | undefined
}

const TransactionSummary = ({ transaction }: TransactionSummaryProps) => {

    const powerBadgeClassnames = clsx(
        !transaction && "hidden",
        "p-2 py-1 rounded-full text-xs font-medium flex",
        !transaction?.shareholder.hasPower && "bg-red-100 text-red-500",
        !transaction?.shareholder.powerVerified && "bg-yellow-100 text-yellow-700",
        transaction?.shareholder.hasPower && transaction?.shareholder.powerVerified && "bg-green-100 text-green-500"
    )

    return (
        <dl className="sm:divide-y sm:divide-gray-200">
            <TransactionSummaryItem name="Accionista">
                {transaction?.shareholder.fullName}
                <div className="flex mt-2">
                    <p className={powerBadgeClassnames}>
                        {transaction?.shareholder.powerVerified ? <CheckIcon className="h-4 mr-2" /> : <XIcon className="h-4 mr-2" />}
                        {!transaction?.shareholder.hasPower && <span >No tiene poder</span>}
                        {transaction?.shareholder.hasPower && !transaction?.shareholder.powerVerified && <span>Poder no verificado</span>}
                        {transaction?.shareholder.powerVerified && <span>Poder verificado</span>}
                    </p>
                </div>

            </TransactionSummaryItem>
            {
                transaction?.type === transactionTypes.CREDIT ?
                    <TransactionSummaryItem name="Tipo de transacción">{transaction?.type}</TransactionSummaryItem>
                    :
                    <TransactionSummaryItem name="Tipo de transacción">{transaction?.type} {!transaction?.sellMotive ? " - Acciones Desbloqueadas" : " - Venta Anticipada"}</TransactionSummaryItem>

            }
            <TransactionSummaryItem name="Cantidad de acciones">{transaction?.amount}</TransactionSummaryItem>
            <TransactionSummaryItem name="Total en euros">{transaction && formatNumberToEuroCurrencyString(transaction.amountEuro)}</TransactionSummaryItem>
            <TransactionSummaryItem name="Total en pesos">{transaction && formatNumberToPesosCurrencyString(transaction.amountCOP)}</TransactionSummaryItem>
            <TransactionSummaryItem name="Fecha de la transacción">{transaction && formatDateTimeToString(transaction?.createdAt)}</TransactionSummaryItem>
            <TransactionSummaryItem name="Documentos">
                <DocumentList documents={transaction?.documents} />
            </TransactionSummaryItem>
            <TransactionSummaryItem name="Identificador único de transacción">{transaction?._id}</TransactionSummaryItem>
        </dl>
    )

}

export default TransactionSummary