import MainLayout from "components/Layout/MainLayout"
import Tabs, { TabOption } from "components/Navigation/Tabs/Tabs"
import PageHeader from "components/PageHeader/PageHeader"
import queryKeys from "config/queries"
import axios from "helpers/axios"
import { useEffect, useState } from "react"
import TransactionList from "./components/TransactionList"
import DownloadTransactionsReport from "./components/DownloadTransactionsReport"
import { useQuery } from "react-query"
import Transaction from "types/Transaction"
import transactionTypes from "config/transactionTypes"
import transactionStates from "config/transactionStates"
import routes from "config/routes"
import { useHistory } from "react-router"
import Button from "components/Button/Button"
import { InformationCircleIcon } from "@heroicons/react/outline"
import useCreateMassiveStateUpdates from "hooks/useCreateMassiveStateUpdates"

const tabOption: Array<TabOption> = [
    { label: "Compras pendientes", id: "buy_pending" },
    { label: "Ventas pendientes", id: "sell_pending" },
    { label: "BAE pendientes", id: "bae_pending" },
    { label: "Aceptadas en Colombia", id: "accepted_locally" },
    { label: "Finalizadas", id: "accepted" },
    { label: "Todas", id: "all" }
]

const Transactions = () => {

    const [selectedTab, setSelectedTab] = useState<string | number>(tabOption[0].id)

    const history = useHistory()

    useEffect(() => {
        history.push({
            pathname: routes.TRANSACTIONS,
            search: '?tab=' + selectedTab
        })
        switch (selectedTab) {
            case "pending": {
                setQuery(`state=${transactionStates.PENDING_ADMIN}&state=${transactionStates.PENDING_PAYMENT}&state=${transactionStates.PENDING_SHAREHOLDER}&state=${transactionStates.ACCEPTED_LOCALLY}`)
                break
            }
            case "buy_pending": {
                setQuery(`state=${transactionStates.PENDING_ADMIN}&state=${transactionStates.PENDING_PAYMENT}&state=${transactionStates.PENDING_SHAREHOLDER}&type=${transactionTypes.CREDIT}`)
                break
            }
            case "sell_pending": {
                setQuery(`state=${transactionStates.PENDING_ADMIN}&state=${transactionStates.PENDING_PAYMENT}&state=${transactionStates.PENDING_SHAREHOLDER}&type=${transactionTypes.DEBIT}&state=${transactionStates.ACCEPTED_LOCALLY}`)
                break
            }
            case "accepted_locally": {
                setQuery(`state=${transactionStates.ACCEPTED_LOCALLY}`)
                break
            }
            case "accepted": {
                setQuery(`state=${transactionStates.ACCEPTED}`)
                break
            }
            case "bae_pending": {
                setQuery(`state=${transactionStates.PENDING_ADMIN}&state=${transactionStates.PENDING_SHAREHOLDER}&type=${transactionTypes.BAE}`)
                break
            }
            default: setQuery("")
        }

    }, [selectedTab])

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const selectedTab = params.get('tab');
        if (selectedTab) {
            setSelectedTab(selectedTab)
        }
    }, [])

    const { updateStateUpdateMassively, isUpdatingStates } = useCreateMassiveStateUpdates()

    const [query, setQuery] = useState("")

    const { data: transactions } = useQuery([queryKeys.TRANSACTIONS, query], () => axios.get<Array<Transaction>>(`/transactions/?${query}`).then(res => res.data))


    const handleMassiveStateUpdate = () => {
        if (!transactions) return
        updateStateUpdateMassively(transactions, transactionStates.ACCEPTED)
    }

    return (
        <MainLayout>
            <div className="grid gap-4">
                <PageHeader title="Transacciones" />
                <DownloadTransactionsReport />
                <Tabs options={tabOption} selectedTab={selectedTab} onTabChange={(id) => setSelectedTab(id)} />

                {
                    (selectedTab === "accepted_locally" && (transactions && transactions?.length > 0)) &&
                    <div className="rounded-md bg-blue-100 p-4 flex-1">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3 flex-1 md:flex md:justify-between">
                                <p className="text-sm text-blue-700">Puedes finalizar todas las transacciones aceptadas. Si algunas transacciones deben ser rechazadas, recházalas antes de finalizar todas las otras</p>
                                <p className="mt-3 text-sm md:mt-0 md:ml-6 min-w-0 flex-shrink-0">
                                    <Button isLoading={isUpdatingStates} onClick={handleMassiveStateUpdate}>Finalizar {transactions?.length} {transactions?.length === 1 ? "transacción" : "transacciones"}</Button>
                                </p>
                            </div>
                        </div>
                    </div>
                }


                <TransactionList transactions={transactions} />

            </div>
        </MainLayout>
    )

}

export default Transactions