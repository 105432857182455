import clsx from "clsx"
import { TabOption } from "./Tabs"

interface TabProps {
    option: TabOption
    onSelect: (id: string | number) => void
    selected: boolean
    isLast: boolean
    isfirst: boolean
}

const Tab = ({ option, onSelect, selected, isfirst = false, isLast = false }: TabProps) => {

    const tabClassNames = clsx(
        "text-gray-900 group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 focus:outline-none",
        isfirst && "rounded-l-lg",
        isLast && "rounded-r-lg"
    )

    return (
        <button onClick={() => onSelect(option.id)} aria-current="page" className={tabClassNames}>
            <span>{option.label}</span>
            {
                selected && <span aria-hidden="true" className="bg-blue-500 absolute inset-x-0 bottom-0 h-0.5"></span>
            }
        </button>
    )

}

export default Tab