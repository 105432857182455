import Document from "types/Document"
import { useMutation } from "react-query"
import axios from "helpers/axios"
import { base64ToDocument } from "utils/functions"

interface DocumentListItemProps {
    document: Document
}

const DocumentListItem = ({ document }: DocumentListItemProps) => {

    const { mutate: downloadDocument, isLoading } = useMutation((query: string) => axios.get(`/transactions/files/${query}`).then(res => res.data), {
        onSuccess: (reportURL) => {
            base64ToDocument(reportURL, document.name);
        }
    })

    const download = (document: Document) => {
        const cadena = document.url.split("/")
        const idDocument = cadena[cadena.length - 1]
        downloadDocument(idDocument)
    }

    return (
        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
            <div className="w-0 flex-1 flex items-start">
                <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd" />
                </svg>
                <span className="ml-2 flex-1 w-0 truncate ">
                    {document.name}
                </span>
            </div>
            <div className="ml-4 flex-shrink-0">
                <button onClick={ () => download( document) } className="font-medium text-blue-600 hover:text-blue-500">
                    Descargar
                </button>
            </div>
        </li>
    )

}

export default DocumentListItem