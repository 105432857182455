import User from "types/User"
import UserListItem from "./UserListItem"

interface UsersListProps {
    users: Array<User>
}

const UsersList = ({ users }: UsersListProps) => {

    return (
        <div>
            <ul className="divide-y divide-gray-200">
                {
                    users?.map(user => <UserListItem user={user} key={user._id} />)
                }
            </ul>
        </div>
    )

}

export default UsersList