
interface TransactionSummaryItemProps {
    children?: React.ReactNode
    name?: string
}

const TransactionSummaryItem = ({ name, children }: TransactionSummaryItemProps) => {

    return (
        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">
                {name}
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {children}
            </dd>
        </div>
    )

}

export default TransactionSummaryItem