import { ChevronRightIcon } from "@heroicons/react/outline"
import Toggle from "components/Toggle/Toggle"
import useEditSellMotive from "hooks/useEditSellMotive"
import SellMotive from "types/SellMotive"

interface SellMotiveListItemProps {
    sellMotive: SellMotive
}

const SellMotiveListItem = ({ sellMotive }: SellMotiveListItemProps) => {

    const { enableOrDisable, addRequiredDocumentType, removeRequiredDocumentType } = useEditSellMotive(sellMotive._id)

    return (

        <li className="py-4">
            <div className="flex items-center space-x-4">
                <div className="min-w-0 flex-1">
                    <div className="flex justify-between mb-2">
                        <p className="text-sm font-medium text-gray-900 mr-2.5">{sellMotive.name} {sellMotive.code && `(${sellMotive.code})`}</p>
                        <Toggle leftLabel="Habilitado?" onChange={enableOrDisable} enabled={sellMotive.enabled} />
                    </div>
                    {
                        sellMotive.requiredDocumentTypes.map(doc => (
                            <p className="flex items-center text-sm text-gray-500 w-9/12">
                                <ChevronRightIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                {doc.name}
                            </p>
                        ))
                    }
                </div>
            </div>
        </li>

        // <div className="pb-8">
        //     <div className="flex justify-between mb-2">
        //         <p className="text-gray-800 font-medium">{sellMotive.name} {sellMotive.code && `(${sellMotive.code})`}</p>
        //         <Toggle leftLabel="Habilitado?" onChange={enableOrDisable} enabled={sellMotive.enabled} />
        //     </div>
        //     <div>
        //         {
        //             sellMotive.requiredDocumentTypes.map(doc => (
        //                 <div className="flex flex-col md:flex-row items-start justify-between text-gray-600">
        //                     <div className="flex items-center text-gray-600">
        //                         <svg className="h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        //                             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
        //                         </svg>
        //                         <div className="mx-2">
        //                             {doc.name}
        //                         </div>
        //                     </div>
        //                     {/* <div>
        //                         <Toggle leftLabel="Requerido ?" onChange={enableOrDisable} enabled={sellMotive.enabled} />
        //                     </div> */}
        //                 </div>
        //             ))
        //         }
        //     </div>
        // </div >
    )

}

export default SellMotiveListItem