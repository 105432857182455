import Button from "components/Button/Button"
import { Controller, useForm } from "react-hook-form"
import Card from "components/Card/Card"
import { DownloadIcon } from "@heroicons/react/outline"
import RadioGroup from "components/RadioGroup/RadioGroup"
import useDownloadTransactionsReport from "hooks/useDownloadTransactionsReport"

const reportOptions = [
    {
        name: "Compras listas para enviar a Francia",
        description: "Incluye las aceptaciones de BAE, los TOP-UP y compras voluntarias, en el formato que se debe enviar a Francia",
        id: `allCredits`
    },
    {
        name: "Ventas listas para enviar a Francia",
        description: "Incluye las ventas anticipadas y ventas después de 5 años, en el formato que se debe enviar a Francia",
        id: `debit`
    },
    {
        name: "Todas la transacciones pendientes",
        description: "Incluye las compras, ventas, aceptaciones de BAE que NO han sido finalizadas",
        id: `pending`
    },
    {
        name: "Todas la transacciones finalizadas",
        description: "Incluye las compras, ventas, aceptaciones de BAE que han sido finalizadas",
        id: `accepted`
    }
]

const DownloadTransactionsReport = () => {

    const { isLoadingReport, downloadReport } = useDownloadTransactionsReport()

    const { handleSubmit, control } = useForm({
        reValidateMode: "onBlur"
    })

    const onSubmit = async (data: any) => downloadReport(data.reportType)

    // const onSubmit = (data: any) => console.log(data.reportType);


    return (
        <Card title="Reportes de transacciones" collapsable>
            <form className="grid gap-4" onSubmit={handleSubmit(onSubmit)}>
                {/* <div className="grid gap-4 md:grid-cols-2">
                    <Input label="Desde" name="from" type="date" ref={register({ required: "Ingresar una fecha de inicio" })} error={errors.from?.message} />
                    <Input label="Hasta" name="to" type="date" ref={register({ required: "Ingresar una fecha de fin" })} error={errors.to?.message} />
                </div> */}
                <Controller
                    defaultValue={reportOptions[0].id}
                    control={control}
                    name="reportType"
                    render={
                        ({ onChange, value }) => <RadioGroup
                            label="Elegir un tipo de reporte"
                            options={reportOptions}
                            value={value}
                            onChange={onChange} />
                    }
                />
                <div className="flex justify-end space-x-2">
                    <Button type="submit" isLoading={isLoadingReport}><DownloadIcon className="h-4 mr-2" /> Descargar el reporte</Button>
                </div>
            </form>
        </Card>
    )

}

export default DownloadTransactionsReport