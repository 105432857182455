import SellMotive from "types/SellMotive"
import SellMotiveListItem from "./SellMotiveListItem"

interface SellMotiveListProps {
    sellMotives: Array<SellMotive> | undefined
}

const SellMotiveList = ({ sellMotives }: SellMotiveListProps) => {

    return (
        <div className="mt-6 flow-root">
            <ul role="list" className="-my-5 divide-y divide-gray-200">
            {
                sellMotives?.map(sellMotive => <SellMotiveListItem sellMotive={sellMotive} />)
            }
            </ul>
        </div>
    )

}

export default SellMotiveList