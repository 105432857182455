import clsx from "clsx"
import React from "react"

interface ButtonProps {
    disabled?: boolean
    children: React.ReactNode
    variant?: "primary" | "secondary" | "info"
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
    type?: "button" | "submit" | "reset"
    size?: "small" | "medium" | "large"
    isLoading?: boolean
}

const Button = ({
    disabled = false,
    children,
    variant = "primary",
    onClick,
    type = "button",
    size = "medium",
    isLoading = false }: ButtonProps) => {

    const buttonTypeClass = clsx(
        "inline-flex items-center justify-center border border-transparent font-medium rounded-md shadow-sm text-white",
        disabled === false && variant === "primary" && "text-white bg-blue-600 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500",
        disabled === false && variant === "secondary" && "border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500",
        disabled === false && variant === "info" && "px-4 py-2 border border-blue-300 text-sm text-blue-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500",
        size === "small" && "px-3 py-2 text-sm leading-4",
        size === "medium" && "px-4 py-2  text-sm",
        size === "large" && "px-4 py-2 text-md justify-center",
        disabled && "text-gray-900 bg-gray-300 border-gray-500 text-opacity-50"
    )
    const labelClassNames = clsx(
        "h-5 flex items-center"
    )

    return (
        <button
            type={type}
            className={buttonTypeClass}
            onClick={onClick}
            disabled={disabled}
        >
            {
                isLoading && <svg className="animate-spin delay-200 -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            }
            <div className={labelClassNames}>
                {children}
            </div>
        </button>
    )

}

export default Button