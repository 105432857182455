import clsx from "clsx"
import { SettingsSection } from "pages/Settings/Settings"

interface SettingsMenuItemProps {
    option: SettingsSection
    onSelect: (section: SettingsSection) => void
    selected: boolean
}

const SettingsMenuItem = ({ option, onSelect, selected }: SettingsMenuItemProps) => {

    const containerClassNames = clsx(
        "w-full group mt-1 border-l-4 px-3 py-2 flex items-center text-sm font-medium focus:outline-none",
        selected
            ? "bg-blue-50 border-blue-500 text-teal-700 hover:bg-blue-50 hover:text-blue-500"
            : "border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900"
    )

    const iconClassNames = clsx(
        "flex-shrink-0 -ml-1 mr-3 h-6 w-6",
        selected
            ? "text-blue-500 group-hover:text-blue-500"
            : "text-gray-400 group-hover:text-gray-500"
    )

    return (
        <button onClick={() => onSelect(option)} className={containerClassNames}>
            <div className={iconClassNames}>
                {option.icon}
            </div>
            <span className="truncate">
                {option.title}
            </span>
        </button >
    )

}

export default SettingsMenuItem