import { Disclosure, Transition } from "@headlessui/react"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline"

interface CardProps {
    title?: string
    subtitle?: string
    children?: React.ReactNode
    collapsable?: boolean
}

const Card = ({ title, subtitle, children, collapsable = false }: CardProps) => {

    return (
        <Disclosure>
            {({ open }) => (
                <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
                    <div className="flex items-center">
                        <div className="flex-1 px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                {title}
                            </h3>
                            <p className="mt-1 text-sm text-gray-500">
                                {subtitle}
                            </p>
                        </div>
                        {
                            collapsable && <Disclosure.Button className="mr-6 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded">
                                {
                                    open
                                        ? <ChevronUpIcon className="h-5 w-5" />
                                        : <ChevronDownIcon className="h-5 w-5" />
                                }


                            </Disclosure.Button>
                        }

                    </div>
                    <Transition
                        show={open || !collapsable}
                        enter={collapsable ? "transition duration-100 ease-out" : ""}
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                    >
                        <Disclosure.Panel static>
                            <div className="px-4 py-5 sm:p-6">
                                {children}
                            </div>
                        </Disclosure.Panel>
                    </Transition>
                </div>
            )}
        </Disclosure>
    )

}

export default Card