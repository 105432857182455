import { useState } from "react"
import Shareholder from "types/Shareholder"
import ShareholderDetailsSlideOver from "./ShareholderDetailsSlideOver"
import ShareholderListItem from "./ShareholderListItem"

interface ShareholderListProps {
    shareholders: Array<Shareholder> | undefined
    setDeleteShareholding: (show: boolean) => void
    deleteShareholding: boolean
}

const ShareholderList = ({ shareholders, setDeleteShareholding, deleteShareholding }: ShareholderListProps) => {

    const [selectedShareholderId, setSelectedShareholderId] = useState<string | undefined>()

    return (
        <div className="flex flex-col">

            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full table-fixed divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Accionista
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Empresa
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Salario
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Poder
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Poder verificado
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32 break-words">
                                        Descargar poder
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Validar el poder
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Días transcurridos
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {
                                    shareholders?.map(shareholder => <ShareholderListItem key={shareholder._id} shareholder={shareholder} onSelect={setSelectedShareholderId} setDeleteShareholding={setDeleteShareholding} deleteShareholding={deleteShareholding}/>)
                                }
                            </tbody>
                        </table>
                        {
                            !shareholders && <p className=" p-4 text-gray-500 text-center">No hay accionistas todavía</p>
                        }
                    </div>
                </div>
            </div>
            <ShareholderDetailsSlideOver shareholderId={selectedShareholderId} onClose={() => setSelectedShareholderId(undefined)} />
        </div>
    )

}

export default ShareholderList