import { Link } from "react-router-dom"
import clsx from "clsx"



interface StatisticListItemProps {
    value: number | string
    label: string
    icon: React.ReactNode
    path?: string,
}

const StatisticListItem = ({ value, label, icon, path }: StatisticListItemProps) => {

    return (
        <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
                <div className="flex items-center">
                    <div className="flex-shrink-0">
                        <div className=" h-6 w-6 text-gray-400">
                            {icon}
                        </div>
                    </div>
                    <div className="ml-5 w-0 flex-1">
                        <dl>
                            <dt className="text-sm font-medium text-gray-500 truncate">
                                {label}
                            </dt>
                            <dd>
                                <div className="text-lg font-medium text-gray-900">
                                    {value ? value : "-"}
                                </div>
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
            {
                path && <div className="bg-gray-50 px-5 py-3">
                    <div className="text-sm">
                        <Link to={path} className="font-medium text-cyan-700 hover:text-cyan-900">
                            Ver más
                        </Link>
                    </div>
                </div>
            }

        </div>
    )

}

export default StatisticListItem