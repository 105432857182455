import { PlusIcon } from "@heroicons/react/outline"
import Button from "components/Button/Button"
import MainLayout from "components/Layout/MainLayout"
import PageHeader from "components/PageHeader/PageHeader"
import queryKeys from "config/queries"
import axios from "helpers/axios"
import { useState } from "react"
import { useQuery } from "react-query"
import BAEList from "./components/BAEList"
import UploadBAEModal from "./components/UploadBaeModal"

const BAE = () => {

    const [uploadModalVisible, setUploadModalVisible] = useState(false)

    //const { data: bae } = useQuery(queryKeys.BAE, () => axios.get(`/bae`))

    return (
        <MainLayout>
            <PageHeader
                title={`BAE + PRIMA EXCEPCIONAL ${(new Date).getFullYear()}`}
                actions={[<Button onClick={() => setUploadModalVisible(true)}><PlusIcon className="h-5 mr-2" />Cargar un archivo de BAE</Button>]} />
            <div className="mt-6">
                <BAEList />
            </div>
            <UploadBAEModal visible={uploadModalVisible} onClose={() => setUploadModalVisible(false)} />
        </MainLayout>
    )

}

export default BAE
