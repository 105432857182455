import ShareValue from "types/ShareValue"
import ShareValueListItem from "./ShareValueListItem"

interface ShareValuesListProps {
    shareValues: Array<ShareValue> | undefined
}

const ShareValuesList = ({ shareValues }: ShareValuesListProps) => {

    return (
        <ul>
            {
                shareValues?.map((shareValue, index) => <ShareValueListItem key={shareValue._id} shareValue={shareValue} nextShareValue={shareValues[index - 1]} lastShareValue={shareValues[index + 1]} />)
            }
        </ul>
    )

}

export default ShareValuesList