import clsx from "clsx"
import React, { LegacyRef, RefObject } from "react"

interface InputProps {
    label?: string
    placeholder?: string
    error?: string | boolean | undefined
    type?: "textarea" | "text" | "number" | "date"
    name?: string
    disabled?: boolean
    step?: string
}

const Input = React.forwardRef(({ label, placeholder, error, type = "text", name, disabled, step }: InputProps,
    ref: string | ((instance: HTMLInputElement | null) => void) | RefObject<HTMLInputElement> | null | undefined | LegacyRef<HTMLTextAreaElement>) => {


    const inputClassNames = clsx(
        "block w-full pr-10 sm:text-sm rounded-md shadow-sm",
        error
            ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500"
            : "border-gray-300 focus:ring-blue-500 focus:border-blue-500"
    )

    return (
        <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">{label}</label>
            <div className="mt-1 relative rounded-md shadow-sm">
                {
                    type === "textarea"
                        ? <textarea disabled={disabled} name={name} rows={5} className={inputClassNames} ref={ref as LegacyRef<HTMLTextAreaElement>}></textarea>
                        : <input step={step} disabled={disabled} name={name} type={type} ref={ref as RefObject<HTMLInputElement>} className={inputClassNames} placeholder={placeholder} aria-invalid="true" aria-describedby="email-error" autoComplete="false" />
                }

                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    {error && <svg className="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                    </svg>}
                </div>
            </div>
            {
                error && typeof error === "string" && <p className="mt-2 text-sm text-red-600" id="email-error">{error}</p>
            }

        </div >
    )

})

export default Input