import { SettingsSection } from "pages/Settings/Settings"
import SettingsMenuItem from "./SettingsMenuItem"

interface SettingMenuProps {
    options: Array<SettingsSection>
    selectedSection: SettingsSection
    onSectionChange: (section: SettingsSection) => void
}

const SettingMenu = ({ options, selectedSection, onSectionChange }: SettingMenuProps) => {

    return (
        <nav>
            {
                options.map((option: any) => <SettingsMenuItem option={option} key={option.id} onSelect={onSectionChange} selected={selectedSection.id === option.id} />)
            }
        </nav>
    )

}

export default SettingMenu