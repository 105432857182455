import { useMutation, useQuery } from "react-query";
import React, { useEffect, useState } from "react";
import MainLayout from "components/Layout/MainLayout";
import PageHeader from "components/PageHeader/PageHeader";
import queryKeys from "config/queries";
import axios from "helpers/axios";
import Shareholder from "types/Shareholder";
import ShareholderList from "./components/ShareholderList";

import Button from "components/Button/Button";
import {
  CurrencyDollarIcon,
  SearchIcon,
  UserGroupIcon,
  DownloadIcon,
} from "@heroicons/react/outline";
import UploadShareholdersModal from "./components/UploadShareholdersModal";
import UploadSalariesModal from "./components/UploadSalariesModal";
import { base64ToFile } from "utils/functions";

const Shareholders = () => {
  const [shouldSearch, setShouldSeach] = useState(true);

  const [
    showUploadShareholdersModal,
    setShowUploadShareholdersModal,
  ] = useState(false);

  const [showUploadSalariesModal, setShowUploadSalariesModal] = useState(false);

  const [search, setSearch] = useState("");
  const [deleteShareholding, setDeleteShareholding] = useState(false);
  const { data: shareholders, isLoading } = useQuery(
    [queryKeys.SHAREHOLDERS, search],
    () =>
      axios
        .get<Array<Shareholder>>(`/shareholders?search=${search}`)
        .then((res) => res.data),
    { enabled: shouldSearch }
  );

  useEffect(() => {
    setShouldSeach(false);
    const delayDebounceFn = setTimeout(() => {
      setShouldSeach(true);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search, deleteShareholding]);

  const { mutate: downloadShareholders, isLoading: isLoadingDownloadShareholders } = useMutation(() => axios.get(`/shareholders/excel`, { responseType: 'blob' }).then(res => res.data), {
    onSuccess: (reportURL) => {
        base64ToFile(reportURL, "accionistas.xls");
    }
})

  return (
    <MainLayout>
      <UploadShareholdersModal
        visible={showUploadShareholdersModal}
        onClose={() => setShowUploadShareholdersModal(false)}
      />
      <UploadSalariesModal
        visible={showUploadSalariesModal}
        onClose={() => setShowUploadSalariesModal(false)}
      />
      <div className="flex flex-col space-y-4">
        <div>
          <PageHeader
            title="Accionistas"
            actions={
              <div className="md:space-x-2 flex flex-col md:flex-row space-y-2 md:space-y-0 w-full">
                <Button onClick={() => downloadShareholders()}>
                  <DownloadIcon className="h-5 mr-2" />
                  Descargar accionistas
                </Button>
                <Button onClick={() => setShowUploadShareholdersModal(true)}>
                  <UserGroupIcon className="h-5 mr-2" />
                  Crear accionistas
                </Button>
                <Button onClick={() => setShowUploadSalariesModal(true)}>
                  <CurrencyDollarIcon className="h-5 mr-2" /> Actualizar
                  salarios
                </Button>
              </div>
            }
          />
          <div className="mt-6 flex rounded-md shadow-sm">
            <div className="relative flex items-stretch flex-grow focus-within:z-10">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                className="p-2 focus:ring-indigo-500 focus:border-blue-500 block w-full rounded rounded-l-md pl-10 sm:text-sm border-gray-300"
                placeholder="Buscar"
              />
              {isLoading && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <svg
                    className="animate-spin delay-200 -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              )}
            </div>
          </div>
        </div>
        <ShareholderList
          shareholders={shareholders}
          setDeleteShareholding={setDeleteShareholding}
          deleteShareholding={deleteShareholding}
        />
      </div>
    </MainLayout>
  );
};

export default Shareholders;
