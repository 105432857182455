import Button from "components/Button/Button";
import Input from "components/Input/Input";
import queryKeys from "config/queries";
import roles from "config/roles";
import axios from "helpers/axios";
import { useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import SettingsSectionLayout from "../SettingsSectionLayout";
import UsersList from "./UsersList";

const UserAccesses = () => {
  const queryClient = useQueryClient();

  const { data: users } = useQuery([queryKeys.DASHBOARD_USERS], () =>
    axios.get("/users").then((res) => res.data)
  );

  const { register, handleSubmit, errors, reset } = useForm({
    reValidateMode: "onChange",
  });

  const onSubmit = async (data: any) => {
    try {
      await axios.post("/users", {
        email: data.email.toLowerCase(),
        role: roles.ADMIN,
      });
      queryClient.invalidateQueries(queryKeys.DASHBOARD_USERS);
      reset();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <SettingsSectionLayout
      title="Accesos a la plataforma"
      subtitle="Configura quien puede usar la plataforma administrador"
    >
      <form className="grid gap-4" onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Email Decathlon"
          name="email"
          type="text"
          placeholder="nombre.apellido@decathlon.com"
          ref={register({
            required: true,
            validate: {
              isDecathlonEmail: (data) => {
                if (data.split("@")[1] !== "decathlon.com") {
                  return "Ingresar un correo corporativo decathlon";
                }
              },
            },
          })}
          error={errors.email?.message}
        />
        <div className="flex justify-end space-x-2">
          <Button type="submit">Dar acceso</Button>
        </div>
      </form>
      <div className="mt-4">
        {/* <h2 className="text-lg leading-6 font-medium text-gray-900">Usuarios con acceso</h2> */}
        <UsersList users={users} />
      </div>
    </SettingsSectionLayout>
  );
};

export default UserAccesses;
