import Button from "components/Button/Button"
import AppLayout from "components/Layout/AppLayout"
import roles from "config/roles"
import routes from "config/routes"
import useSession from "hooks/useSession"
import { Dashboard, Settings, TransactionDetails, Transactions, Login, Shareholders } from "pages"
import BAE from "pages/BAE/BAE"
// import { InMaintenance } from "pages/InMaintenance/InMaintenance"
import ProtectedRoute from "./ProtectedRoute"
import { DocumentPDF } from "pages/ExchangeRate/Components/DocumentPDF"
import { ExchangeRate } from "pages/ExchangeRate/Components/ExchangeRate"

interface AppRoutingProps {

}

const AppRouting = ({ }: AppRoutingProps) => {

    // return <InMaintenance />

    const { currentUser, logout } = useSession()

    if (!currentUser) return <Login />

    if (currentUser && !currentUser.role) return <div className="min-h-screen flex flex-col items-center justify-center"><p className="mb-4">No tienes acceso a esta app!</p> <Button onClick={logout}>Logout</Button></div>

    return (
        <AppLayout>
            <ProtectedRoute exact path={routes.HOME} component={Dashboard} />
            <ProtectedRoute exact path={routes.TRANSACTIONS} component={Transactions} requiredRoles={[roles.ADMIN]} />
            <ProtectedRoute exact path={routes.SETTINGS} component={Settings} requiredRoles={[roles.ADMIN]} />
            <ProtectedRoute exact path={routes.TRANSACTION_DETAILS} component={TransactionDetails} requiredRoles={[roles.ADMIN]} />
            <ProtectedRoute exact path={routes.SHAREHOLDERS} component={Shareholders} requiredRoles={[roles.ADMIN]} />
            <ProtectedRoute exact path={routes.BAE} component={BAE} requiredRoles={[roles.ADMIN]} />
            <ProtectedRoute exact path={routes.EXCHANGERATE} component={ExchangeRate} requiredRoles={[roles.ADMIN]} />
        </AppLayout>
    )

}

export default AppRouting