import BuyingPeriod from "types/BuyingPeriod"
import BuyingPeriodListItem from "./BuyingPeriodListItem"


interface BuyingPeriodListProps {
    buyingPeriods: Array<BuyingPeriod> | undefined;
}

const BuyingPeriodList = ({ buyingPeriods }: BuyingPeriodListProps) => {

    return (
        <ul>
            {
                buyingPeriods?.map(buyingPeriod => <BuyingPeriodListItem buyingPeriod={buyingPeriod} key={buyingPeriod._id} />)
            }
        </ul>
    )

}

export default BuyingPeriodList