import queryKeys from "config/queries"
import axios from "helpers/axios"
import { useQueryClient } from "react-query"

const useDeleteBuyingPeriod = (id: string) => {

    const queryClient = useQueryClient()

    const deleteBuyingPeriod = async () => {

        try {
            await axios.delete(`/buying-periods/${id}`)
            queryClient.invalidateQueries(queryKeys.BUYING_PERIODS)
        } catch (err) {
            throw err
        }
    }

    return deleteBuyingPeriod
}

export default useDeleteBuyingPeriod