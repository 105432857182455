import Button from "components/Button/Button"
import useSession from "hooks/useSession"

interface SidebarUserFooterProps {

}

const SidebarUserFooter = ({ }: SidebarUserFooterProps) => {

    const { currentUser, logout } = useSession()

    return (
        <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
            <div className="flex-shrink-0 w-full block">
                <div className="flex items-center">
                    <div className="ml-3">
                        <p className="text-base md:text-sm font-medium text-gray-700 ">
                            {currentUser?.fullName}
                        </p>
                        <p className="text-sm md:text-xs font-medium text-gray-500">
                            {currentUser?.role}
                        </p>

                    </div>
                    <div className="w-full mt-2 flex justify-end">
                        <Button onClick={logout} variant="secondary">logout</Button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default SidebarUserFooter