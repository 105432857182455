export const dateToHTMLInputFormat = (date: Date | string): string => new Date(date).toISOString().substring(0, 10)

export const HTMLInputDateToDate = (stringDate: string): Date => new Date(stringDate + 'T00:00')

export const formatDateToString = (date: Date | string): string => new Intl.DateTimeFormat('es-CO', {
    year: "numeric",
    month: "long",
    day: "2-digit"
}).format(new Date(date))

export const formatDateTimeToString = (date: Date | string): string => new Intl.DateTimeFormat('es-CO', {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
}).format(new Date(date))

export const formatNumberToPesosCurrencyString = (number: number): string => new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
}).format(number)

export const formatNumberToEuroCurrencyString = (number: number): string => new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
}).format(number)