enum queryKeys {
    DOCUMENT_TYPES = "documentTypes",
    TRANSACTIONS = "transactions",
    SHARE_VALUES = "shareValues",
    BUYING_PERIODS = "buyingPeriods",
    DASHBOARD_USERS = "dashboardUsers",
    ANNOUNCEMENTS = "announcements",
    SHAREHOLDERS = "shareholders",
    SELL_MOTIVES = "sellMotives",
    STATISTICS = "statistics",
    EXCEL_PATH = "excelPath",
    BAE = "bae",
    EXCHANGERATE = "exchangeRate"

}

export default queryKeys