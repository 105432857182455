import { Page, Text, Image, Document, StyleSheet, View, Font } from '@react-pdf/renderer';

Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 1000 }
    ]
});

// Create styles
const styles = StyleSheet.create({
    page: {
        padding: '0 0 50 0',
        fontFamily: "Open Sans",
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
    },
    col_3_buttom: {
        border: '1px solid black', width: '30px', height: '13px', top: '1.5'
    },
    col_3_text: {
        fontSize: '5.5px', padding: '4px 2px'
    },
    title: {
        fontSize: '9px', fontWeight: 'bold', padding: '2px 2px'
    },
    title_cell: {
        fontSize: '7px', padding: '0px 2px'
    },
    content_cell: {
        fontSize: '8px', padding: '1px 2px'
    },
    content_cell_row: {
        fontSize: '6px', padding: '1px 2px'
    },
    select_cell: {
        bottom: '-2', left: '9', position: 'absolute', fontSize: '14'
    },
    container_cell: {
        border: '1px solid black', width: '100%', height: '16px'
    },
});

type FormData = {
    state: string
    xToUsd: number
    xToCop: number
    dateF4: string
    company: string
    type: string
};

type props = {
    fecha: FormData
    data: DataRest[]
};


type DataRest = {
    _id: string
    professionalEmail: string
    company: string
    documentType: string
    documentNumber: string
    firstName: string
    lastName: string
    address: string
    transactions: [
        {
            type: string
            state: string
            amount: number
            valueEuro: number
            createdAt: string
            euroAmount: number
        }
    ]
}

export const DocumentPDFv2 = ({ data, fecha }: props) => {

    const { xToUsd, xToCop, dateF4 } = fecha;

    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    return (
        <Document>
            {
                data ? data.map((a: DataRest) => {
                    return (
                        <Page size="LETTER" style={styles.page} key={a._id}>
                            <View>
                                <Image style={{ width: "160px", padding: "10 0 0 20" }} src={'logo777.png'} />
                            </View>

                            <View style={{ width: '90%', margin: 'auto' }}>
                                <View style={{ flexDirection: "row" }}>
                                    <View style={{ textAlign: 'center', width: '85%', padding: '4px 0' }}>
                                        <Text style={{ fontWeight: 'bold', fontSize: '10px', padding: '0px 2px' }}>Información de datos mínimos requeridos para las </Text>
                                        <Text style={{ fontWeight: 'bold', fontSize: '10px', padding: '0px 2px' }}>operaciones de cambio por inversiones internacionales </Text>
                                        <Text style={{ fontSize: '10px', padding: '0px 2px' }}>Circular Reglamentaria Externa DCINP - 83 del 12 de Septiembre de 2023 </Text>
                                    </View>
                                    <View style={{ width: '20%', margin: 'auto', padding: '4px 0' }}>
                                        <Text style={styles.title}>I. TIPO DE OPERACIÓN</Text>
                                        <View style={{ padding: '6px 0', flexDirection: "row" }}>
                                            <View style={{ width: '100%' }}>
                                                <View style={{ width: '100%', flexDirection: "row" }}>
                                                    <View style={{ border: '1px solid black', height: '16px', width: '70%' }}>
                                                        <Text style={styles.title_cell}>1. NUMERO:</Text>
                                                    </View>
                                                    <View style={{ border: '1px solid black', height: '16px', width: '30%' }}>
                                                        <Text style={styles.content_cell}>1</Text>
                                                    </View>
                                                </View>
                                                <View style={{ width: '100%', flexDirection: "row" }}>
                                                    <View style={{ border: '1px solid black', height: '16px', width: '70%' }}>
                                                        <Text style={styles.title_cell}>2. OPERACIÓN DE:</Text>
                                                    </View>
                                                    <View style={{ border: '1px solid black', height: '16px', width: '30%' }}>
                                                        <Text style={styles.content_cell}>E</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>

                            <View style={{ width: '90%', margin: 'auto' }}>
                                <Text style={styles.title}>II. IDENTIFICACIÓN DE LA DECLARACIÓN</Text>
                                <View style={{ flexDirection: "row" }}>
                                    <View style={{ width: '22%' }}>
                                        <Text style={styles.title_cell}>3. CIUDAD </Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}>Bogotá</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '34%' }}>
                                        <Text style={styles.title_cell}>4. DEPARTAMENTO </Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}>Bogotá</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '22%' }}>
                                        <Text style={styles.title_cell}>5. FECHA (AAAA\MM\DD)</Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}>{dateF4}</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '22%' }}>
                                        <Text style={styles.title_cell}>6. NUMERO (Uso IMC)</Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}></Text>
                                        </View>
                                    </View>
                                </View>
                            </View>


                            <View style={{ width: '90%', margin: 'auto' }}>
                                <Text style={styles.title}>III. IDENTIFICACIÓN DE LA DECLARACIÓN DE CAMBIO ANTERIOR</Text>
                                <View style={{ flexDirection: "row" }}>
                                    <View style={{ width: '26%' }}>
                                        <Text style={styles.title_cell}>7. NIT del IMC o No. Cta compensación </Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}></Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '26%' }}>
                                        <Text style={styles.title_cell}>8. Numero Deuda Externa (si aplica)</Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}></Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '24%' }}>
                                        <Text style={styles.title_cell}>9. Fecha Op Inicial </Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}></Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '24%' }}>
                                        <Text style={styles.title_cell}>10. Numero Op Inicial </Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}></Text>
                                        </View>
                                    </View>
                                </View>
                            </View>


                            <View style={{ width: '90%', margin: 'auto' }}>
                                <Text style={styles.title}>IV. DESTINO DE LA INVERSION</Text>
                                <View style={{ flexDirection: "row", margin: 'auto' }}>
                                    <Text style={{ fontSize: '6px', padding: '0px 2px', fontWeight: 'bold', marginRight: '230' }}>IECO - INVERSION EXTRANJERA EN COLOMBIA</Text>
                                    <Text style={{ fontSize: '6px', padding: '0px 2px', fontWeight: 'bold' }}>ICEX - INVERSION COLOMBIANA EN EL EXTERIOR</Text>
                                </View>
                                <View style={{ flexDirection: "row" }}>
                                    <View style={{ width: '43%' }}>
                                        <View style={{ width: '100%', flexDirection: "row" }}>
                                            <View style={styles.col_3_buttom}>
                                                <Text style={styles.content_cell_row}>EMPA</Text>
                                            </View>
                                            <Text style={styles.col_3_text}>EMPRESAS (Incluye sociedades, empresas unipersonales)</Text>
                                        </View>
                                        <View style={{ width: '100%', flexDirection: "row", top: -4 }}>
                                            <View style={styles.col_3_buttom}>
                                                <Text style={styles.content_cell_row}>CSRE</Text>
                                            </View>
                                            <Text style={styles.col_3_text}>CAPITAL ASIGNADO; SUCURSAL REGIMEN ESPECIAL</Text>
                                        </View>
                                        <View style={{ width: '100%', flexDirection: "row", top: -7 }}>
                                            <View style={styles.col_3_buttom}>
                                                <Text style={styles.content_cell_row}>CSRG</Text>
                                            </View>
                                            <Text style={styles.col_3_text}>CAPITAL ASIGNADO; SUCURSAL REGIMEN GENERAL</Text>
                                        </View>
                                        <View style={{ width: '100%', flexDirection: "row", top: -10 }}>
                                            <View style={styles.col_3_buttom}>
                                                <Text style={styles.content_cell_row}>ISDC</Text>
                                            </View>
                                            <Text style={styles.col_3_text}>INV. SUPLEMENTARIA SUC. REGIMEN GENERAL Y ESPECIAL </Text>
                                        </View>
                                        <View style={{ width: '100%', flexDirection: "row", top: -13 }}>
                                            <View style={styles.col_3_buttom}>
                                                <Text style={styles.content_cell_row}>PATO</Text>
                                            </View>
                                            <Text style={styles.col_3_text}>PATRIMONIOS AUTÓNOMOS</Text>
                                        </View>
                                        <View style={{ width: '100%', flexDirection: "row", top: -16 }}>
                                            <View style={styles.col_3_buttom}>
                                                <Text style={styles.content_cell_row}>EFDC</Text>
                                            </View>
                                            <Text style={styles.col_3_text}>ENCARGOS FIDUCIARIOS</Text>
                                        </View>
                                        <View style={{ width: '100%', flexDirection: "row", top: -19 }}>
                                            <View style={styles.col_3_buttom}>
                                                <Text style={styles.content_cell_row}>ISDC</Text>
                                            </View>
                                            <Text style={styles.col_3_text}>Capital Asignado Suc. Bancos y Cñias de Seguros</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '33%' }}>
                                        <View style={{ width: '100%', flexDirection: "row" }}>
                                            <View style={styles.col_3_buttom}>
                                                <Text style={styles.content_cell_row}>INMU</Text>
                                            </View>
                                            <Text style={styles.col_3_text}>INMUEBLES</Text>
                                        </View>
                                        <View style={{ width: '100%', flexDirection: "row", top: -4  }}>
                                            <View style={styles.col_3_buttom}>
                                                <Text style={styles.content_cell_row}>BOCE</Text>
                                            </View>
                                            <Text style={styles.col_3_text}>BOCEAS (Bonos oblig. convertibles en acciones)</Text>
                                        </View>
                                        <View style={{ width: '100%', flexDirection: "row", top: -7 }}>
                                            <View style={styles.col_3_buttom}>
                                                <Text style={styles.content_cell_row}>ACON</Text>
                                            </View>
                                            <Text style={styles.col_3_text}>Actos o Contratos sin Part. en el Capital.</Text>
                                        </View>
                                        <View style={{ width: '100%', flexDirection: "row", top: -10 }}>
                                            <View style={styles.col_3_buttom}>
                                                <Text style={styles.content_cell_row}>INPF</Text>
                                            </View>
                                            <Text style={styles.col_3_text}>INVERSION DE PORTAFOLIO</Text>
                                        </View>
                                        <View style={{ width: '100%', flexDirection: "row", top: -13 }}>
                                            <View style={styles.col_3_buttom}>
                                                <Text style={styles.content_cell_row}>FCPR</Text>
                                            </View>
                                            <Text style={styles.col_3_text}>Fondos de Capital Privado.</Text>
                                        </View>
                                        <View style={{ width: '100%', flexDirection: "row", top: -16 }}>
                                            <View style={styles.col_3_buttom}>
                                                <Text style={styles.content_cell_row}>AINT</Text>
                                            </View>
                                            <Text style={styles.col_3_text}>ACTIVOS INTANGIBLES</Text>
                                        </View>
                                        <View style={{ width: '100%', flexDirection: "row", top: -19 }}>
                                            <View style={styles.col_3_buttom}>
                                                <Text style={styles.content_cell_row}>EDCO</Text>
                                            </View>
                                            <Text style={styles.col_3_text}>ESTABLECIMIENTOS DE COMERCIO</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '24%' }}>
                                        <View style={{ width: '100%', flexDirection: "row" }}>
                                            <View style={styles.col_3_buttom}>
                                                <Text style={styles.content_cell_row}>EMPA</Text>
                                            </View>
                                            <Text style={styles.col_3_text}>SOCIEDADES EXTRANJERAS</Text>
                                        </View>
                                        <View style={{ width: '100%', flexDirection: "row" }}>
                                            <View style={styles.col_3_buttom}>
                                                <Text style={styles.content_cell_row}>IFAE</Text>
                                                <Text style={styles.select_cell}>x</Text>
                                            </View>
                                            <Text style={{ fontSize: '5.5px', padding: '0px 2px', width: '90px', position: 'absolute', margin: '0 0 0 30' }}>
                                                NV. FINANCIERAS Y ACTIVOS RADICADOS EN EL EXTERIOR
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>


                            <View style={{ width: '90%', margin: 'auto', bottom: '15' }}>
                                <Text style={styles.title}>V. IDENTIFICACIÓN DE LA EMPRESA RECEPTORA O ADMINISTRADOR LOCAL DEL FONDO DE INVERSION (PORTAFOLIO)</Text>
                                <Text style={{ fontSize: '7px', fontWeight: 'bold', padding: '0 2px' }}>(LAS EMPRESAS EN CONSTITUCIÓN DEBEN INDICAR EL CÓDIGO RC "Receptor en constitución" EN LA CASILLA 11)</Text>
                                <View style={{ paddingTop: '8px', flexDirection: "row" }}>
                                    <View style={{ width: '12%' }}>
                                        <Text style={styles.title_cell}>11. Tipo </Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}></Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '33%' }}>
                                        <Text style={styles.title_cell}>12. Numero de Identificación</Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}></Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '55%' }}>
                                        <Text style={styles.title_cell}>13. Nombre</Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}></Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ display: 'flex', flexDirection: "row" }}>
                                    <View style={{ width: '14%' }}>
                                        <Text style={styles.title_cell}>14. País</Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}></Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '28%' }}>
                                        <Text style={styles.title_cell}>15. Correo Electrónico </Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}></Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '16%' }}>
                                        <Text style={styles.title_cell}>16. Código CIIU </Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}></Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '21%' }}>
                                        <Text style={styles.title_cell}>17. Teléfono (Receptor Nacional)</Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}></Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '21%' }}>
                                        <Text style={styles.title_cell}>18. Dirección (Receptor Nacional)</Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}></Text>
                                        </View>
                                    </View>
                                </View>
                            </View>


                            <View style={{ width: '90%', margin: 'auto', bottom: '10' }}>
                                <Text style={styles.title}>VI. IDENTIFICACIÓN DEL INVERSIONISTA (NACIONAL O EXTRANJERO ) O FONDO DE INVERSION (PORTAFOLIO)</Text>
                                <View style={{ display: 'flex', flexDirection: "row" }}>
                                    <View style={{ width: '13%' }}>
                                        <Text style={styles.title_cell}>19. Tipo</Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}>{a.documentType}</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '32%' }}>
                                        <Text style={styles.title_cell}>20. Numero de Identificación</Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}>{a.documentNumber}</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '55%' }}>
                                        <Text style={styles.title_cell}>21. Nombre</Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}>{`${a.firstName} ${a.lastName}`}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ display: 'flex', flexDirection: "row" }}>
                                    <View style={{ width: '10%' }}>
                                        <Text style={styles.title_cell}>22. País</Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}>Colombia</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '34%' }}>
                                        <Text style={styles.title_cell}>23. Correo electrónico</Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}>{a.professionalEmail}</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '12%' }}>
                                        <Text style={styles.title_cell}>24. Código CIIU</Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}>0010</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '20%' }}>
                                        <Text style={styles.title_cell}>25. Teléfono (Inv. Nacional)</Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}>6015186404</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '24%' }}>
                                        <Text style={styles.title_cell}>26. Dirección (Inv. Nacional)</Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}>{a.address}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>


                            <View style={{ width: '90%', margin: 'auto', bottom: '5' }}>
                                <Text style={styles.title}>VII. DESCRIPCIÓN DE LA OPERACIÓN</Text>
                                <View style={{ flexDirection: "row" }}>
                                    <View style={{ width: '12%' }}>
                                        <Text style={styles.title_cell}>27. Numeral</Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}>4585</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '13%' }}>
                                        <Text style={styles.title_cell}>28. Código moneda</Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}>EUR</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '40%' }}>
                                        <Text style={styles.title_cell}>29. Valor Moneda De Negociación</Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}>{formatter.format(a.transactions[0].euroAmount)}</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '35%' }}>
                                        <Text style={styles.title_cell}>30. Tipo de Cambio a Dólar Americano</Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}>{xToUsd.toFixed(4)}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ flexDirection: "row", marginTop: '12px' }}>
                                    <View style={{ width: '20%' }}>
                                        <Text style={styles.title_cell}>31. Valor en dolares (UDS) </Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}>{`${formatter.format(a.transactions[0].euroAmount * xToUsd)}`}</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '20%' }}>
                                        <Text style={styles.title_cell}>32. Tasa de cambio a Pesos </Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}>{formatter.format(xToCop)}</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '20%' }}>
                                        <Text style={styles.title_cell}>33. Valor en pesos </Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}>{`${formatter.format((a.transactions[0].euroAmount * xToUsd) * xToCop)}`}</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '20%', bottom: '9.6' }}>
                                        <Text style={styles.title_cell}>34. Participaciones (Si marca cero, debe llenar casilla 35) </Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}></Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '20%' }}>
                                        <Text style={styles.title_cell}>35. Motivo sin Participaciones</Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}></Text>
                                        </View>
                                    </View>
                                </View>
                            </View>


                            <View style={{ width: '90%', margin: 'auto', bottom: '10' }}>
                                <Text style={styles.title}>VIII. INFORMACIÓN DEL RESIDENTE PERSONA JURÍDICA (Persona Natural residente, solo diligencia punto: 1. Naturaleza)</Text>
                                <View style={{ padding: '6px 0', flexDirection: "row" }}>
                                    <View style={{ width: '40%', bottom: '10' }}>
                                        <View style={{ width: '100%' }}>
                                            <Text style={styles.title_cell}>1. Naturaleza</Text>
                                            <View style={styles.container_cell}>
                                                <Text style={styles.content_cell}>Persona natural</Text>
                                            </View>
                                        </View>
                                        <View style={{ width: '100%', marginTop: '8px' }}>
                                            <Text style={styles.title_cell}>2. Tipo de empresa</Text>
                                            <View style={styles.container_cell}>
                                                <Text style={styles.content_cell}></Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ width: '45%', marginLeft: '15%', bottom: '10' }}>
                                        <View style={{ width: '100%', flexDirection: "row", marginTop: '10px' }}>
                                            <Text style={styles.title_cell}>3. ¿Es vigilado por alguna superintendencia?</Text>
                                            <View style={{ border: '1px solid black', width: '30', height: '16px' }}>
                                                <Text style={styles.content_cell}></Text>
                                            </View>
                                        </View>
                                        <View style={{ width: '100%', marginTop: '7.5px' }}>
                                            <Text style={styles.title_cell}>3.1. Si se encuentra vigilado por alguna superintendencia, indique cual</Text>
                                            <View style={styles.container_cell}>
                                                <Text style={styles.content_cell}></Text>
                                            </View>
                                        </View>
                                        <View style={{ width: '100%', marginTop: '8px' }}>
                                            <Text style={styles.title_cell}>4. Sector</Text>
                                            <View style={styles.container_cell}>
                                                <Text style={styles.content_cell}></Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>

                            <View style={{ width: '90%', margin: 'auto', bottom: '25' }}>
                                <Text style={styles.title}>IX. IDENTIFICACIÓN DEL DECLARANTE</Text>
                                <View style={{ flexDirection: "row" }}>
                                    <View style={{ width: '55%' }}>
                                        <Text style={styles.title_cell}>38. NOMBRE </Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}>ISABELLA RAMOS KLOTZ</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '45%' }}>
                                        <Text style={styles.title_cell}>39. FIRMA</Text>
                                        <View style={styles.container_cell}>
                                            <Text style={styles.content_cell}></Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </Page>
                    )
                }) : ""
            }
        </Document>
    )
};
