import { ExclamationIcon } from "@heroicons/react/outline"
import MainLayout from "components/Layout/MainLayout"
import PageHeader from "components/PageHeader/PageHeader"
import routes from "config/routes"
import axios from "helpers/axios"
import useSession from "hooks/useSession"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import Statistics from "./components/Statistics"


const Dashboard = () => {

    const { currentUser } = useSession()

    const { data: warnings } = useQuery("warnings", () => axios.get(`/config/warnings`).then(res => res.data))

    return (
        <div>
            <MainLayout>
                <div className="grid gap-4">
                    <PageHeader title={`¡Hola ${currentUser?.fullName}!`} />
                    {
                        warnings?.shareValue && <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                    <p className="text-sm text-yellow-700">
                                        El valor del acción no está definido, sin está información no se puede crear transacciones{' '}
                                        <Link to={`${routes.SETTINGS}?selected=share-value`} className="font-medium underline text-yellow-700 hover:text-yellow-600">
                                            Definir el valor del acción ahora
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        warnings?.documentTypes && <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                    <p className="text-sm text-yellow-700">
                                        No definiste ningún tipo de documento, los accionistas no van a poder adjuntar documentos a las transacciones{' '}
                                        <Link to={`${routes.SETTINGS}?selected=document-types`} className="font-medium underline text-yellow-700 hover:text-yellow-600">
                                            Definir los tipos de documento ahora
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        warnings?.sellMotives && <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                    <p className="text-sm text-yellow-700">
                                        No definiste ningún motivos de venta, los accionistas no van a poder realizar ventas anticipadas{' '}
                                        <Link to={`${routes.SETTINGS}?selected=sell-motive`} className="font-medium underline text-yellow-700 hover:text-yellow-600">
                                            Definir los motivos de venta ahora
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    }

                    <Statistics />

                </div>
            </MainLayout>
        </div >

    )

}

export default Dashboard