import Button from "components/Button/Button"
import Input from "components/Input/Input"
import queryKeys from "config/queries"
import axios from "helpers/axios"
import { useForm } from "react-hook-form"
import { useQuery, useQueryClient } from "react-query"
import SettingsSectionLayout from "../SettingsSectionLayout"
import AnnouncementList from "./AnnouncementList"

interface AnnouncementsProps {

}

const Announcements = ({ }: AnnouncementsProps) => {

    const queryClient = useQueryClient()

    const { data: announcements } = useQuery([queryKeys.ANNOUNCEMENTS], () => axios.get("/announcements").then(res => res.data))

    const { errors, register, handleSubmit, reset } = useForm({
        reValidateMode: "onBlur"
    })

    const onSubmit = async (data: any) => {
        try {
            await axios.post("/announcements", data)
            queryClient.invalidateQueries(queryKeys.ANNOUNCEMENTS)
            reset()
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <SettingsSectionLayout title="Anuncios" subtitle="Comunica con los accionistas Decathlon">
            <form className="grid gap-4" onSubmit={handleSubmit(onSubmit)}>
                <Input label="Titulo" name="title" placeholder="Viene el proximo periodo de compra!" ref={register({ required: true })} error={errors.name} />
                <Input label="Mensaje" name="text" type="textarea" placeholder="Viene el proximo periodo de compra!" ref={register({ required: true })} error={errors.description} />
                <div className="flex justify-end space-x-2">
                    <Button variant="secondary" type="reset">Cancelar</Button>
                    <Button type="submit">Publicar</Button>
                </div>
            </form>
            <div className="mt-4">
                <AnnouncementList announcements={announcements} />
            </div>
        </SettingsSectionLayout>
    )

}

export default Announcements