import clsx from "clsx"
import routes from "config/routes"
import transactionStates from "config/transactionStates"
import transactionTypes from "config/transactionTypes"
import { formatDateToString, formatNumberToEuroCurrencyString } from "helpers"
import { Link } from "react-router-dom"
import Transaction from "types/Transaction"
import { ArrowLeftIcon, ArrowRightIcon, ClockIcon, GiftIcon, QuestionMarkCircleIcon } from "@heroicons/react/outline";
import { Switch } from '@headlessui/react'
import { useMutation, useQueryClient } from "react-query"
import queryKeys from "config/queries"
import axios from "../../../helpers/axios"

interface TransactionListItemProps {
    transaction: Transaction
}

const getTransactionIcon = (transactionType: string) => {
    const iconClassnames = "h-4"
    switch (transactionType) {
        case transactionTypes.CREDIT:
            return <ArrowRightIcon className={iconClassnames} />
        case transactionTypes.DEBIT:
            return <ArrowLeftIcon className={iconClassnames} />
        case transactionTypes.BAE:
            return <GiftIcon className={iconClassnames} />
        case transactionTypes.TOP_UP:
            return <GiftIcon className={iconClassnames} />
        default:
            return <QuestionMarkCircleIcon className={iconClassnames} />
    }
}

const getTransactionDescription = (transaction: Transaction): string => {
    let description = `${transaction.shareholder.fullName} `
    switch (transaction.type) {
        case transactionTypes.DEBIT: {
            description += "vendió "
            break
        }
        case transactionTypes.CREDIT: {
            description += "compró "
            break
        }
        case transactionTypes.BAE: {
            description += "aceptó un BAE de "
            break
        }
        case transactionTypes.TOP_UP: {
            description += "recibió un TOP UP de "
            break
        }
    }
    description += `${transaction.amount} acciones`
    return description
}

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

const TransactionListItem = ({ transaction }: TransactionListItemProps) => {

    const queryClient = useQueryClient()

    const amoutLabelClassNames = clsx(
        "font-medium p-2 rounded-md",
        (transaction.type === transactionTypes.CREDIT || transaction.type === transactionTypes.BAE || transaction.type === transactionTypes.TOP_UP)
        && !(transaction.state === transactionStates.REJECTED || transaction.state === transactionStates.CANCELLED)
        && "text-green-600 bg-green-50 ",
        transaction.type === transactionTypes.DEBIT && "text-gray-800",
        (transaction.state === transactionStates.REJECTED || transaction.state === transactionStates.CANCELLED) && "line-through text-gray-600"
    )

    const icon = getTransactionIcon(transaction.type)
    const description = getTransactionDescription(transaction)

    const stateLabelClassNames = clsx(
        "mt-2 text-sm py-1 px-4 rounded-full font-medium",
        transaction.state === transactionStates.CANCELLED && "bg-gray-200",
        transaction.state === transactionStates.ACCEPTED && "bg-green-100 text-gray-600",
        transaction.state === transactionStates.REJECTED && "bg-gray-100 text-gray-600",
        transaction.state === transactionStates.PENDING_ADMIN && "bg-red-100 text-red-400",
        transaction.state === transactionStates.PENDING_SHAREHOLDER && "bg-gray-200 text-gray-600",
        transaction.state === transactionStates.PENDING_PAYMENT && "bg-gray-100 text-gray-600",
        transaction.state === transactionStates.ACCEPTED_LOCALLY && "bg-gray-100 text-gray-600",
    )

    const onChange = async (state: boolean) => {
        // console.log(`${transaction.countdown ? true : false}`);
        // console.log(state);
        mutate(!state)
    }

    const { mutate, isLoading } = useMutation((state: boolean) => axios.post(`/transactions/countdown/`, { transactionId: transaction._id, state: state }).then(res => res.data), {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.TRANSACTIONS)
        },
        onError: (error) => {
            console.log(error)
        }
    })

    return (
        <Link to={`${routes.TRANSACTIONS}/${transaction._id}`}>
            <div className="px-4 py-4 sm:px-6 hover:bg-gray-50">
                <div className="flex items-center justify-between">
                    <div className="flex items-start">
                        <div className="text-gray-600 bg-gray-200 p-2 rounded-full mr-3 ">
                            {icon}
                        </div>
                        <div className="flex flex-col items-start ">
                            <p className={`font-medium ${(transaction.state === transactionStates.REJECTED || transaction.state === transactionStates.CANCELLED) && "line-through"}`}>{description}</p>
                            <p className="text-gray-600">{formatDateToString(transaction.createdAt)}</p>
                            {
                                transaction.state !== transactionStates.ACCEPTED && <p className={stateLabelClassNames}> {transaction.state}</p>
                            }
                            {/* <p>{`${transaction.countdown ? true : false}  ${transaction.countdown}`}</p> */}
                        </div>
                    </div>

                    <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
                        {/* <div className="flex-shrink-0">
                            <ClockIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-blue-400" aria-hidden="true" />
                        </div> */}
                        {
                            transaction.type == "debit" &&
                            <>
                                <div className="flex-shrink-0">
                                    <ClockIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-blue-400" aria-hidden="true" />
                                </div>
                                <Switch
                                    checked={transaction.countdown ? true : false}
                                    onChange={() => onChange(transaction.countdown ? true : false)}
                                    className={classNames(
                                        transaction.countdown ? 'bg-blue-600' : 'bg-gray-200',
                                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2'
                                    )}
                                >
                                    <span className="sr-only">Use setting</span>
                                    <span
                                        aria-hidden="true"
                                        className={classNames(
                                            transaction.countdown ? 'translate-x-5' : 'translate-x-0',
                                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                        )}
                                    />
                                </Switch>
                            </>
                        }
                        <p className={amoutLabelClassNames}>
                            {transaction.type === transactionTypes.DEBIT && "-"}{formatNumberToEuroCurrencyString(transaction.amountEuro)}
                        </p>
                    </div>

                    {/* <p className={amoutLabelClassNames}>
                        {formatNumberToEuroCurrencyString(transaction.amountEuro)}
                    </p> */}
                </div>
            </div>
        </Link >
    )

}

export default TransactionListItem