import clsx from "clsx"
import Button from "components/Button/Button"
import useEditUserAccess from "hooks/useEditUserAccess"
import useSession from "hooks/useSession"
import React from "react"
import User from "types/User"

interface UserListItemProps {
    user: User;
}

const UserListItem = ({ user }: UserListItemProps) => {

    console.log(user)
    const { currentUser } = useSession()

    const { removeUserAccess } = useEditUserAccess(user._id)

    const buttonClassName = clsx(
        "mt-2 justify-end",
        user._id === currentUser?._id ? "hidden" : "flex"
    )

    return (
        <li className="py-4 grid lg:grid-cols-4">
            <div className="text-sm lg:col-span-3">
                <p className="font-medium text-gray-800">{user.fullName}</p>
                <p className="text-gray-600">{user.email}</p>
            </div>
            <div className={buttonClassName}>
                <Button variant="secondary" size="small" onClick={removeUserAccess}>Quitar el acceso</Button>
            </div>
        </li>
    )

}

export default UserListItem