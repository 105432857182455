import queryKeys from "config/queries"
import axios from "helpers/axios"
import { useQueryClient } from "react-query"

const useDeleteShareValue = (id: string) => {

    const queryClient = useQueryClient()

    const deleteShareValue = async () => {

        try {
            await axios.delete(`/share-values/${id}`)
            queryClient.invalidateQueries(queryKeys.SHARE_VALUES)
        } catch (err) {
            throw err
        }
    }

    return deleteShareValue
}

export default useDeleteShareValue