import Document from "types/Document"
import DocumentListItem from "./DocumentListItem"

interface DocumentListProps {
    documents: Array<Document> | undefined
}

const DocumentList = ({ documents }: DocumentListProps) => {

    return (
        <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
            {
                (documents && documents.length > 0)
                    ? documents.map(document => <DocumentListItem document={document} />)
                    : <p className="p-4 text-center text-gray-600">No hay documentos adjuntados</p>
            }
        </ul>
    )

}

export default DocumentList