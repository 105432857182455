import clsx from "clsx"
import Menu from "components/Navigation/Menu/Menu"
import Sidebar from "components/Sidebar/Sidebar"
import { useState } from "react"

interface AppLayoutProps {
    children: React.ReactNode
}

const AppLayout = ({ children }: AppLayoutProps) => {

    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const overlayClassNames = clsx(
        "fixed inset-0",
        isMenuOpen
            ? "transition-opacity ease-linear duration-300 opacity-100"
            : "transition-opacity ease-linear duration-300 opacity-0"
    )

    const mobileSidebarClassNames = clsx(
        "relative flex-1 flex flex-col max-w-xs w-full bg-white",
        isMenuOpen
            ? "transition ease-in-out duration-300 transform translate-x-0"
            : "transition ease-in-out duration-300 transform -translate-x-full"
    )

    const mobileSidebarContainerClassNames = clsx(
        "fixed inset-0 flex z-40",
        isMenuOpen
            ? "transform translate-x-0"
            : "transition ease-in-out delay-300 transform -translate-x-full"
    )

    const closeButtonClassNames = clsx(
        "ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white",
        isMenuOpen
            ? "transition-opacity ease-linear duration-300 opacity-100"
            : "transition-opacity ease-linear duration-300 opacity-0"
    )

    return (

        <div className="h-screen flex overflow-hidden bg-gray-100" >
            <div className="md:hidden" >
                <div className={mobileSidebarContainerClassNames}>
                    <div className={overlayClassNames}>
                        <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
                    </div>
                    <div className={mobileSidebarClassNames}>
                        <div className="absolute top-0 right-0 -mr-12 pt-2">
                            <button onClick={() => setIsMenuOpen(isMenuOpen => !isMenuOpen)} className={closeButtonClassNames}>
                                <span className="sr-only">Close sidebar</span>
                                <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <Sidebar />
                    </div>
                    <div className="flex-shrink-0 w-14">
                        {/*<!-- Force sidebar to shrink to fit close icon --> */}
                    </div>
                </div>
            </div>

            {/*<!-- Static sidebar for desktop --> */}
            <div className="hidden md:flex md:flex-shrink-0">
                <div className="flex flex-col w-64">
                    <Sidebar />
                </div>
            </div>
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
                    <button onClick={() => setIsMenuOpen(isMenuOpen => !isMenuOpen)} className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Open sidebar</span>
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </button>
                </div>

                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex={0}>
                    {children}
                </main>
            </div>
        </div>

    )

}

export default AppLayout
