import clsx from "clsx"
import { formatDateToString } from "helpers"
import useDeleteBuyingPeriod from "hooks/useDeleteBuyingPeriod"
import BuyingPeriod from "types/BuyingPeriod"

interface BuyingPeriodListItemProps {
    buyingPeriod: BuyingPeriod
}

const BuyingPeriodListItem = ({ buyingPeriod }: BuyingPeriodListItemProps) => {

    const deleteBuyingPeriod = useDeleteBuyingPeriod(buyingPeriod._id)

    const className = clsx(
        "flex items-center justify-between text-sm md:text-base",
        new Date(buyingPeriod.from) < new Date() && new Date() < new Date(buyingPeriod.to) && "text-green-600",
        new Date(buyingPeriod.to) < new Date() && "text-gray-400"
    )

    return (
        <li className="py-1">
            <div className={className}>
                {formatDateToString(buyingPeriod.from)}
                <svg className="h-5 mx-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
                <div className="flex items-center">
                    {formatDateToString(buyingPeriod.to)}
                    <button onClick={deleteBuyingPeriod} className="focus:outline-none text-gray-400 ml-2 flex items-center">
                        <svg className="h-4 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                    </button>
                </div>
            </div>
        </li>
    )

}

export default BuyingPeriodListItem