import queryKeys from "config/queries"
import transactionStates from "config/transactionStates"
import axios from "helpers/axios"
import { useMutation, useQueryClient } from "react-query"
import Transaction from "types/Transaction"
import TransactionStateUpdate from "types/TransactionStateUpdate"

const useCreateMassiveStateUpdates = () => {
    const queryClient = useQueryClient()
    const { mutate, isLoading: isUpdatingStates } = useMutation((stateUpdates: Array<Partial<TransactionStateUpdate>>) => axios.post("/transactions/massive/state-updates", { stateUpdates }), {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.TRANSACTIONS)
        }
    })

    const updateStateUpdateMassively = (transactions: Array<Transaction>, state: transactionStates) => {
        const states = transactions.map(transaction => {
            return {
                transactionId: transaction._id,
                state: state
            }
        })
        mutate(states)
    }

    return { updateStateUpdateMassively, isUpdatingStates }
}

export default useCreateMassiveStateUpdates