import './App.css';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import AppRouting from 'components/Routing/AppRouting';
import { SessionContextProvider } from 'contexts/sessionContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';

const queryClient = new QueryClient()

function App() {


  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <SessionContextProvider>
          <Toaster />
          <AppRouting/>
        </SessionContextProvider>
      </Router >
    </QueryClientProvider>
  );
}

export default App;
