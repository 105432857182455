import Button from "components/Button/Button";
import Input from "components/Input/Input";
import queryKeys from "config/queries";
import axios from "helpers/axios";
import { Controller, useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import SettingsSectionLayout from "../SettingsSectionLayout";
import Toggle from "components/Toggle/Toggle";
import { ClipboardListIcon } from "@heroicons/react/outline";
import { notifyError, notifySuccess } from "components/Toast/ToastNotify";

const DocumentTypes = () => {
  const queryClient = useQueryClient();
  const { data: documentTypes } = useQuery([queryKeys.DOCUMENT_TYPES], () =>
    axios.get<Array<DocumentType>>("/document-types").then((res) => res.data)
  );
  //   const { register, handleSubmit, errors } = useForm();
  //   const { control } = useForm({
  //     reValidateMode: "onChange",
  //   });

  const { register, handleSubmit, errors, control } = useForm({
    reValidateMode: "onChange",
  });

  const onSubmit = async (data: any) => {
    try {
      await axios.post("/document-types", data);
      queryClient.invalidateQueries(queryKeys.DOCUMENT_TYPES);
      notifySuccess("El tipo de documento a sido creado")
    } catch (err) {
      console.log(err);
      notifyError("Algo salio mal");
    }
  };

  return (
    <SettingsSectionLayout
      title="Tipos de documento"
      subtitle="Define los tipos de documento que los accionistas podrían tener que proveer"
    >
      <form className="grid gap-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-4">
          <Input
            label="Nombre del tipo documento"
            name="name"
            type="text"
            placeholder="Acta de nacimiento"
            ref={register({ required: true })}
            error={errors.name}
          />
        </div>
        {/* <div className="flex justify-end">  */}
        <div className="flex space-x-4 justify-end">
          {/* <Controller
            control={control}
            defaultValue={false}
            name="countdown"
            render={({ value, onChange }) => (
              <Toggle
                leftLabel="Tiene fecha de expiración"
                enabled={value}
                onChange={onChange}
              />
            )}
          /> */}

          <Button type="submit">Crear</Button>
        </div>
      </form>
      {/* <ul className="pt-4 mt-8 border-t divide-gray-200">
        {documentTypes?.map((documentType) => (
          <li className="py-1 text-gray-700">{documentType.name}</li>
        ))}
      </ul> */}


      <div className="mt-6 flow-root">
        <ul role="list" className="-my-5 divide-y divide-gray-200">
          {documentTypes?.map((person) => (
            <li className="py-4">
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0">
                  <ClipboardListIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                </div>
                <div className="min-w-0 flex-1">
                  <p className="text-sm font-medium text-gray-900">{person.name}</p>
                </div>
                {/* <div>
                  <a
                    href="#"
                    className="inline-flex items-center rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    View
                  </a>
                </div> */}
              </div>
            </li>
          ))}
        </ul>
      </div>


    </SettingsSectionLayout >
  );
};

export default DocumentTypes;
