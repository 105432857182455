import sessionContext from "contexts/sessionContext";
import { useContext } from "react";
import jwt from "jsonwebtoken";
import Token from "types/Token";

const useSession = () => {
  const [token, setToken] = useContext(sessionContext);

  const getDecodedToken = () => {
    if (!token) return undefined;
    return jwt.decode(token) as Token;
  };

  const login = () => {
    window.location.href = `https://idpdecathlon.oxylane.com/as/authorization.oauth2?client_id=${process.env.REACT_APP_SSO_CLIENT_ID}&state=${process.env.REACT_APP_URL}&redirect_uri=${process.env.REACT_APP_API_URL}/v2/auth/dashboard/sso&response_type=code&scope=openid%20profile`;
    // window.location.href = `https://idpdecathlon.oxylane.com/as/authorization.oauth2?client_id=${process.env.REACT_APP_SSO_CLIENT_ID}&state=https://admin.preprod.accionariado.decathlontools.co&redirect_uri=${process.env.REACT_APP_API_URL}/v2/auth/dashboard/sso&response_type=code&scope=openid%20profile`;
  };

  const logout = () => setToken(undefined);

  const currentUser = getDecodedToken();

  return { currentUser, logout, login };
};

export default useSession;
