import { CheckCircleIcon, EyeIcon, TrashIcon, XCircleIcon } from "@heroicons/react/outline"
import routes from "config/routes"
import { formatDateToString } from "helpers"
import useDeleteBAE from "hooks/useDeleteBAE"
import { useHistory } from "react-router"

interface BAEListItemProps {
    bae: any
}

const BAEListItem = ({ bae }: BAEListItemProps) => {

    const deleteBAE = useDeleteBAE(bae._id)
    const history = useHistory()

    return (
        <tr>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {bae.shareholder?.fullName}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {bae.amount}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {
                    bae.accepted ? <CheckCircleIcon className="h-6 w-6 text-green-500" /> : <XCircleIcon className="h-6 w-6 text-yellow-500" />
                }
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {
                    bae.transaction?.createdAt && formatDateToString(bae.transaction.createdAt)
                }
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {
                    bae.accepted
                        ? <button onClick={() => history.push(`${routes.TRANSACTIONS}/${bae.transaction._id}`)} className="focus:outline-none focus:ring-2 focus:ring-blue-500 rounded flex items-center"><EyeIcon className="mr-2 h-4" />Ver la transacción</button>
                        : <button onClick={deleteBAE} className="focus:outline-none focus:ring-2 focus:ring-red-500 rounded"><TrashIcon className="text-red-500 h-6" /></button>
                }
            </td>
        </tr>
    )

}

export default BAEListItem