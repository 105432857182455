import queryKeys from "config/queries"
import axios from "helpers/axios"
import { useMutation, useQueryClient } from "react-query"

const useDeleteBAE = (id: string) => {

    const queryClient = useQueryClient()

    const { mutate } = useMutation(() => axios.delete(`/bae/${id}`), {
        onMutate: async () => {
            await queryClient.cancelQueries(queryKeys.BAE)

            // Snapshot the previous value
            const previousBAEs = queryClient.getQueryData(queryKeys.BAE)

            // Optimistically update to the new value
            queryClient.setQueryData<Array<any> | undefined>(queryKeys.BAE, (oldBAEs: Array<any> | undefined) => {
                if (!oldBAEs) return
                return oldBAEs.filter(bae => bae._id != id)
            })

            // Return a context object with the snapshotted value
            return { previousBAEs }
        },
        onError: (err, newTodo, context) => {
            queryClient.setQueryData(queryKeys.BAE, context?.previousBAEs)
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKeys.BAE)
        },
    })

    const deleteBAE = () => mutate()

    return deleteBAE

}

export default useDeleteBAE