import { useForm } from "react-hook-form";
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import axios from "helpers/axios";
import { DocumentPDF } from "./DocumentPDF";
import { pdf } from "@react-pdf/renderer";
import FileSaver from "file-saver";
import { DocumentPDFv2 } from "./DocumentPDFv2";

type FormData = {
    state: string
    xToUsd: number
    xToCop: number
    dateF4: string
    company: string
    type: string
};

type DataRest = {
    _id: string
    professionalEmail: string
    company: string
    documentType: string
    documentNumber: string
    firstName: string
    lastName: string
    address: string
    transactions: [
        {
            type: string
            state: string
            amount: number
            valueEuro: number
            createdAt: string
            euroAmount: number
        }
    ]
}

export const ExchangeRate = () => {

    const { register, handleSubmit, errors, reset } = useForm<FormData>();

    const onSubmit = (data: FormData): void => {
        console.log(data)
        sendDataPdfExchange(data)
        reset();
    };

    const { mutate: sendDataPdfExchange } = useMutation((data: FormData) => axios.post<Array<DataRest>>(`/forms`, data).then(res => res.data), {
        onSuccess: (dataPDF, variables) => {
            if (dataPDF.length > 0) {
                toast.dismiss()
                toast.loading("En breve se generara tu documento...", { style: { background: "#007DBC", color: "white" } })
                generatePdfDocument(dataPDF, variables)
            } else {
                toast.dismiss()
                toast.success("No hay datos para generar tu documento.", { style: { background: "#FF6600", color: "white" }, duration: 4000, icon: "⚠️" })
            }
        },
        onError: ({ error }) => {
            toast.dismiss()
            toast.error("No fue posible consultar, inténtalo de nuevo.", { style: { background: "#E3262F", color: "white" } })
            console.log(error)
        },
        onMutate: () => {
            toast.loading("Consultando información...", { style: { background: "#007DBC", color: "white" } })
        }
    })

    const generatePdfDocument = async (pdfDetails: DataRest[], variables: FormData) => {
        const blob = await pdf(
            <DocumentPDFv2 data={pdfDetails} fecha={variables} />
        ).toBlob()
        FileSaver.saveAs(blob, `tasacambio${variables.company}-${variables.type}.pdf`)
        toast.dismiss()
        toast.success("Se ha descargado tu documento.", { style: { background: "#008f39", color: "white" }, duration: 4000 })
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} className="p-12 bg-gray-200">
                <div className="space-y-12">
                    <div className="border-b border-gray-900/10 p-12 bg-white rounded-md">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">TASA DE CAMBIO</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Selecciona la configuración adecuada para generar el documento que requieres</p>
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-8">
                            <div className="sm:col-span-4">
                                <label htmlFor="company" className="block text-sm font-medium leading-6 text-gray-900">
                                    Compañía
                                </label>
                                <div className="mt-2">
                                    <select
                                        ref={register({ required: true })}
                                        id="company"
                                        name="company"
                                        autoComplete="company-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    >
                                        <option value={"decathlon"}>Decathlon</option>
                                        <option value={"decoam"}>Decoam</option>
                                    </select>
                                </div>
                            </div>
                            <div className="sm:col-span-4">
                                <label htmlFor="type" className="block text-sm font-medium leading-6 text-gray-900">
                                    Tipo
                                </label>
                                <div className="mt-2">
                                    <select
                                        ref={register({ required: true })}
                                        id="type"
                                        name="type"
                                        autoComplete="type-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    >
                                        <option value={"bae"}>BAE</option>
                                        <option value={"credit"}>Credito</option>
                                        <option value={"debit"}>Debito</option>
                                    </select>
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="xToUsd" className="block text-sm font-medium leading-6 text-gray-900">
                                    xToUsd
                                </label>
                                <div className="mt-2">
                                    <input
                                        ref={register({ required: true, valueAsNumber: true })}
                                        type="number"
                                        step="any"
                                        name="xToUsd"
                                        id="xToUsd"
                                        autoComplete="xToUsd"
                                        className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    {errors.xToUsd && <p className="mt-1 text-sm text-red-600">Este dato es obligatorio</p>}
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="xToCop" className="block text-sm font-medium leading-6 text-gray-900">
                                    xToCop
                                </label>
                                <div className="mt-2">
                                    <input
                                        ref={register({ required: true, valueAsNumber: true })}
                                        type="number"
                                        name="xToCop"
                                        id="xToCop"
                                        autoComplete="xToCop"
                                        className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    {errors.xToCop && <p className="mt-1 text-sm text-red-600">Este dato es obligatorio</p>}
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="dateF4" className="block text-sm font-medium leading-6 text-gray-900">
                                    dateF4
                                </label>
                                <div className="mt-2">
                                    <input
                                        ref={register({ required: true })}
                                        type="text"
                                        name="dateF4"
                                        id="dateF4"
                                        autoComplete="dateF4"
                                        className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    {errors.dateF4 && <p className="mt-1 text-sm text-red-600">Este dato es obligatorio</p>}
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="state" className="block text-sm font-medium leading-6 text-gray-900">
                                    Estado
                                </label>
                                <div className="mt-2">
                                    <select
                                        ref={register({ required: true })}
                                        id="state"
                                        name="state"
                                        autoComplete="state-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    >
                                        <option value={"accepted"}>Aceptado</option>
                                        <option value={"accepted_locally"}>Aceptado en Colombia</option>
                                        <option value={"rejected"}>Rechazada</option>
                                        <option value={"pending_payment"}>Pendiente pago</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 flex items-center justify-end gap-x-6">
                            <button
                                type="submit"
                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Generar documento
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
