import queryKeys from "config/queries"
import axios from "helpers/axios"
import { useMutation, useQuery, useQueryClient } from "react-query"
import DocumentType from "types/DocumentType"
import SellMotive from "types/SellMotive"

const useCreateSellMotive = () => {

    const queryClient = useQueryClient()

    const { data: documentTypes, isSuccess: sellMotiveCreated } = useQuery([queryKeys.DOCUMENT_TYPES], () => axios.get<Array<DocumentType>>(`/document-types`).then(res => res.data))

    const { mutate } = useMutation((sellMotive: SellMotive) => axios.post(`/sell-motives`, sellMotive), {
        onMutate: async (newSellMotive) => {
            await queryClient.cancelQueries(queryKeys.SELL_MOTIVES)

            // Snapshot the previous value
            const previousSellMotives = queryClient.getQueryData(queryKeys.SELL_MOTIVES)

            // Optimistically update to the new value
            queryClient.setQueryData(queryKeys.SELL_MOTIVES, (oldSellMotives: Array<any> | undefined) => oldSellMotives ? [...oldSellMotives, newSellMotive] : [newSellMotive])

            // Return a context object with the snapshotted value
            return { previousSellMotives }
        },
        // onSuccess: () => {
        //     queryClient.invalidateQueries(queryKeys.SELL_MOTIVES)
        // },
        onError: (err, newTodo, context) => {
            //@ts-ignore
            queryClient.setQueryData(queryKeys.SELL_MOTIVES, context?.previousSellMotives)
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKeys.SELL_MOTIVES)
        },
    })

    const createSellMotive = (sellMotive: SellMotive) => mutate(sellMotive)

    return { createSellMotive, documentTypes, sellMotiveCreated }

}

export default useCreateSellMotive