
interface SettingsSectionLayoutProps {
    title?: string;
    subtitle?: string
    children: React.ReactNode
}

const SettingsSectionLayout = ({ title, subtitle, children }: SettingsSectionLayoutProps) => {

    return (
        <div className="py-6 px-4 sm:p-6 lg:pb-8">
            <div className="mb-4">
                <h2 className="text-lg leading-6 font-medium text-gray-900">{title}</h2>
                <p className="mt-1 text-sm text-gray-500">
                    {subtitle}
                </p>
            </div>
            {children}
        </div>
    )

}

export default SettingsSectionLayout